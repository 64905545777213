import { toTypedSchema } from '@vee-validate/yup'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { computed, ExtractPropTypes, onBeforeMount } from 'vue'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { updateMedicalCondition } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/ManageMedicalConditions/queries'
import { ManageMedicalConditionDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/ManageMedicalConditions/shared/types'
import { getMapMedicalAttrsMedicalConditions } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/queries'
import {
  useMedicalConditionAttrsRefApi,
  useMapMedicalAttrsMedicalConditionsApi,
} from '@/pages/PatientProfile/CarePlans/ClinicalSummary/MedicalConditions/shared/store'
import {
  EditMedicalConditionsForm,
  schema,
  editMedicalConditionsProps,
} from './types'

type PropsType = ExtractPropTypes<typeof editMedicalConditionsProps>

/**
 * Set up the EditMedicalConditions component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: medicalConditionAttrRefs } = storeToRefs(
    useMedicalConditionAttrsRefApi()
  )
  const { data: mapMedicalAttrMedicalConditionRefs } = storeToRefs(
    useMapMedicalAttrsMedicalConditionsApi()
  )

  const attributeOptions = computed(() => {
    if (props.updateAttrsMedicalCondition) {
      const mappings = mapMedicalAttrMedicalConditionRefs.value?.filter(
        (maps) =>
          maps.medicalConditionRefId ===
          props.updateAttrsMedicalCondition?.medicalConditionRefId
      )
      const mappedAttrIds = mappings?.map(
        (map) => map.medicalConditionAttributeId
      )
      const attrRefsForSelectedCondition =
        medicalConditionAttrRefs.value?.filter((attr) =>
          mappedAttrIds?.includes(attr.medicalConditionAttributeId)
        )
      return attrRefsForSelectedCondition
        ?.map((attr) => {
          return {
            value: attr.medicalConditionAttributeId,
            label: attr.description,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    }

    return []
  })

  /**
   *
   * @param medicalConditionDisplay
   */
  function medicalConditionActions(
    medicalConditionDisplay: ManageMedicalConditionDisplay
  ) {
    if (medicalConditionDisplay.isArchived) {
      return [{ value: 'isArchived', label: 'Restore' }]
    }

    const unarchivedOptions = [{ value: 'archive', label: 'Archive' }]
    if (medicalConditionDisplay.hasConditionSpecificAttributes) {
      unarchivedOptions.push({
        value: 'editAttributes',
        label: 'Edit Attributes',
      })
    }
    return unarchivedOptions
  }

  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  /**
   *
   * @param value
   * @param medicalConditionDisplay
   */
  async function triggerMedicalConditionAction(
    value: string,
    medicalConditionDisplay: ManageMedicalConditionDisplay
  ) {
    if (!medicalConditionDisplay) {
      useNotificationStore().setNotification({
        message: 'Selected medical condition not found - cannot perform action',
        type: NotificationType.DANGER,
      })
      return
    }

    if (value === 'archive') {
      context.emit('setArchiveMedicalCondition', medicalConditionDisplay)
    } else if (value === 'isArchived') {
      await updateMedicalCondition(medicalConditionDisplay.medicalConditionId, {
        isArchived: false,
      })
      context.emit('refetch')
    } else if (value === 'editAttributes') {
      context.emit('setUpdateAttrsMedicalCondition', medicalConditionDisplay)
    }
  }

  const editAttrsInitialData = computed(() => ({
    conditionSpecificAttributes:
      props.updateAttrsMedicalCondition?.conditionSpecificAttributes,
  }))

  const { handleSubmit } = useForm({
    initialValues: editAttrsInitialData.value,
    validationSchema: toTypedSchema(schema),
  })

  /**
   *
   * @param values
   */
  async function saveAttributes(values: EditMedicalConditionsForm) {
    if (props.updateAttrsMedicalCondition) {
      await updateMedicalCondition(
        props.updateAttrsMedicalCondition.medicalConditionId,
        {
          isArchived: props.updateAttrsMedicalCondition.isArchived,
          conditionSpecificAttributes: values.conditionSpecificAttributes,
        }
      )
      context.emit('refetch')
      context.emit('setUpdateAttrsMedicalCondition', null)
    }
  }

  const onSubmit = handleSubmit(saveAttributes)

  onBeforeMount(async () => {
    if (!mapMedicalAttrMedicalConditionRefs.value) {
      await getMapMedicalAttrsMedicalConditions()
    }
  })

  return {
    // display
    attributeOptions,
    // data
    editAttrsInitialData,
    // dropdown action values
    medicalConditionActions,
    triggerMedicalConditionAction,
    // actions
    onSubmit,
    close,
  }
}
