import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'

/**
 *
 * @param arr
 * takes a string array and sorts and formats it into a string
 * @param doNotMutateStr
 */
export function arrayToStringDisplay(arr: string[], doNotMutateStr = false) {
  let stringDisplay = ''
  const sorted = arr.sort()
  sorted.map((val, index) => {
    if (doNotMutateStr) {
      stringDisplay += val
    } else {
      stringDisplay += startCase(camelCase(val))
    }
    if (index < arr.length - 1) {
      // separate each value with a bullet point
      stringDisplay += ' • '
    }
  })
  return stringDisplay
}

/**
 *
 * @param arr
 * returns null if array is empty
 * otherwise return original array
 */
export function checkForEmptyArr(arr: string[] | null | undefined) {
  if (arr && arr.length) {
    return arr
  }
  return null
}

/**
 *
 * @param str
 * takes a string and replaces commas with a bullet point
 */
export function formatStringDisplay(str: string) {
  return str.replace(/,/g, ' • ')
}
