<template>
  <TModal
    :is-visible="true"
    title="Add Medical Conditions"
    :size="ModalSize.LG"
    :flex="true"
    :allow-overflow="true"
    @close="close"
  >
    <template #preTitle>
      <TIcon class="mr-2 cursor-pointer" icon="chevronLeft" @click="close" />
    </template>
    <div v-if="isLoadingRefs" class="med-refs-loading">
      Loading medical conditions to add...
    </div>
    <div v-else class="space-y-4">
      <AddMedicalConditionInner
        v-for="ref in medicalConditionRefsDisplay"
        :key="ref.medicalConditionRefId ?? ref.description"
        :medical-condition-reference="ref"
        @add="addMedicalCondition"
        @remove="removeMedicalCondition"
        @set-medical-condition-payload="setMedicalConditionPayload"
      />
    </div>

    <template #actions>
      <TMQuaternaryButton label="Cancel" @click="close" />
      <div
        v-if="disableSave"
        v-tooltip.right="
          'Please enter Other medical condition type in the input'
        "
      >
        <TMPrimaryButton
          label="Save"
          :disabled="disableSave"
          @click="onSubmit"
        />
      </div>
      <TMPrimaryButton v-else label="Save" @click="onSubmit" />
    </template>
  </TModal>
</template>
<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TModal from '@/legacy/nashville/TModal.vue'
import AddMedicalConditionInner from './AddMedicalConditionInner/AddMedicalConditionInner.vue'
import { setup } from './controller'
import { addMedicalConditionsProps } from './types'

export default defineComponent({
  components: {
    TMQuaternaryButton,
    AddMedicalConditionInner,
    TMPrimaryButton,
    TModal,
    TIcon,
  },
  props: addMedicalConditionsProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
