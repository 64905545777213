import apiStore from '@/legacy/store/modules/apiBuilder'
import { PhysicalFunctionAssessment } from './types'

export const usePhysicalFunctionAssessmentApi =
  apiStore<PhysicalFunctionAssessment>(
    'physicalFunctionAssessmentApi',
    '/api/care_plan/physical_function_assessment',
    {
      transformData: (d: { data: PhysicalFunctionAssessment[] }) => d,
    }
  )
