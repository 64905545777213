import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useFallRiskApi } from '../shared/store'
import { EditFallRiskForm } from './types'

/**
 *
 * @param fallRiskId
 * @param dirtyData
 */
export async function updatePatientFallRisk(
  fallRiskId: string,
  dirtyData: EditFallRiskForm
) {
  let upserted
  try {
    upserted = await useFallRiskApi().partialUpdate({
      ids: [fallRiskId],
      body: dirtyData,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update fall risk.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated fall risk.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}

/**
 *
 * @param patientId
 * @param dirtyData
 */
export async function createPatientFallRisk(
  patientId: string,
  dirtyData: EditFallRiskForm
) {
  let upserted
  try {
    upserted = await useFallRiskApi().create({
      body: { patientId: patientId, ...dirtyData },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update fall risk.',
      type: NotificationType.DANGER,
    })
    return null
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated fall risk.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}
