import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useCarePlanTransitionsOfCareApi } from '../shared/store'
import { EditTransitionsOfCareForm } from './types'

/**
 *
 * @param transitionsOfCareId
 * @param dirtyData
 */
export async function updateCarePlanTransitionsOfCare(
  transitionsOfCareId: string,
  dirtyData: EditTransitionsOfCareForm
) {
  let upserted
  try {
    upserted = await useCarePlanTransitionsOfCareApi().partialUpdate({
      ids: [transitionsOfCareId],
      body: dirtyData,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update transitions of care.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated transitions of care.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}

/**
 *
 * @param patientId
 * @param dirtyData
 */
export async function createCarePlanTransitionsOfCare(
  patientId: string,
  dirtyData: EditTransitionsOfCareForm
) {
  let upserted
  try {
    upserted = await useCarePlanTransitionsOfCareApi().create({
      body: { patientId: patientId, ...dirtyData },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to create transitions of care.',
      type: NotificationType.DANGER,
    })
    return null
  }
  useNotificationStore().setNotification({
    message: 'Successfully created transitions of care.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}
