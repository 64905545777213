<template>
  <TModal
    :is-visible="true"
    :size="ModalSize.MD"
    title="Archive Medical Condition"
    @close="close"
  >
    <p>Are you sure you want to archive this medical condition?</p>
    <div class="medical-conditions-wrapper items-start space-y-2">
      <h5>
        {{ confirmArchiveMedicalCondition.description }}
      </h5>
      <div v-if="confirmArchiveMedicalCondition.attributesString">
        {{ confirmArchiveMedicalCondition.attributesString }}
      </div>
    </div>
    <template #actions>
      <TMQuaternaryButton
        name="cancel-archive-medical-conditions"
        class="mt-3"
        label="Cancel"
        @click="close"
      />
      <TMPrimaryButton
        name="archive-medical-conditions"
        class="mt-3"
        label="Archive"
        @click="archive"
      />
    </template>
  </TModal>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMQuaternaryButton from '@nashville/button/TMQuaternaryButton.vue'
import { defineComponent } from 'vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { archiveMedicalConditionProps } from './types'

export default defineComponent({
  components: {
    TMQuaternaryButton,
    TMPrimaryButton,
    TModal,
  },
  props: archiveMedicalConditionProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
<style lang="scss">
.medical-conditions-wrapper {
  @apply flex flex-col justify-between py-6 px-4 mb-4 border border-nash-neutral400 rounded-lg;
}
</style>
