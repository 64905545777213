<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Cancer Diagnosis"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div>
            <label class="font-bold">Cancer</label>
            <TMFilterDropdown
              name="cancer"
              label="Cancer"
              class="w-full my-2"
              :initial-value="cancerInitialValue"
              :options="cancerOptions"
              option-label="label"
              option-value="value"
              @update:model-value="setCancerField"
            />
          </div>
          <TMDropdownField
            name="oncologySpreadRefIds"
            label="Spread"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :multiple="true"
            :options="spreadOptions"
            :disabled="showOtherCancerType"
            :model-value="initialData.oncologySpreadRefIds ?? []"
          />
        </div>
        <div>
          <TMDropdownField
            v-if="!showOtherCancerType"
            name="oncologyDiagnosisRefId"
            class="w-full my-2"
            label="Cancer Type"
            option-label="label"
            option-value="value"
            :options="cancerTypeOptions"
            :model-value="initialData.oncologyDiagnosisRefId ?? undefined"
            @update:model-value="setCancerType"
          />
          <TInputText
            v-if="showOtherCancerType"
            class="mt-2 mb-3"
            label="Cancer Type"
            name="otherOncologyDxType"
            :model-value="initialData.otherOncologyDxType"
          />
          <TDatePicker
            name="diagnosisDate"
            label="Diagnosis Date"
            class="flex-grow"
            :is-datetime="false"
            :clearable="false"
            :model-value="initialData.diagnosisDate"
          />
        </div>
      </div>

      <TTextarea
        label="Additional Context"
        name="additionalContext"
        :model-value="initialData.additionalContext ?? ''"
      />

      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="cancer-details-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="cancer-details-save"
          label="Save"
          @click="onSubmit"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'

import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TMFilterDropdown from '@/legacy/nashville/dropdown/TMFilterDropdown.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editCancerDetailsProps } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMDropdownField,
    TModal,
    TTextarea,
    TInputText,
    TMSecondaryButton,
    TDatePicker,
    TMFilterDropdown,
  },
  props: editCancerDetailsProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
