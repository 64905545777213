import { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

export type CarePlanTransitionsOfCare = {
  transitionsOfCareId: UUID
  patientId: UUID
  admissionDate: Date
  dischargeDate: Nullable<Date>
  eventType: EventType
  dischargeDiagnosis: Nullable<string>
  dischargeLocation: Nullable<DischargeLocation>
  isReadmissionRisk: Nullable<ReadmissionRisk>
  hasNewDmeOnDischarge: Nullable<boolean>
  servicesOnDischarge: ServicesOnDischarge[]
  moreThanThreeAdmissionsInLastSixMonths: Nullable<boolean>
  summaryOfEvents: Nullable<string>
  hasElectiveAdmission: Nullable<boolean>
} & Timestamps

export enum DischargeLocation {
  HOME = 'HOME',
  LTACH = 'LTACH',
  ACUTE_REHAB = 'ACUTE_REHAB',
  SUBACUTE_REHAB = 'SUBACUTE_REHAB',
  HALFWAY_HOUSE = 'HALFWAY_HOUSE',
  ASSISTED_LIVING = 'ASSISTED_LIVING',
  LONG_TERM_CARE = 'LONG_TERM_CARE',
  SKILLED_NURSING = 'SKILLED_NURSING',
  HOSPICE = 'HOSPICE',
}

export enum ReadmissionRisk {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum EventType {
  ED_DISCHARGE = 'ED_DISCHARGE',
  INPATIENT_DISCHARGE = 'INPATIENT_DISCHARGE',
  POST_ACUTE_CARE_DISCHARGE = 'POST_ACUTE_CARE_DISCHARGE',
}

export enum ServicesOnDischarge {
  SKILLED_NURSING = 'SKILLED_NURSING',
  WOUND_CARE = 'WOUND_CARE',
  PT_OT = 'PT_OT',
  SPEECH_SWALLOW_THERAPY = 'SPEECH_SWALLOW_THERAPY',
  HOME_HEALTH_AIDE = 'HOME_HEALTH_AIDE',
  DIALYSIS = 'DIALYSIS',
}
