import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { usePatientClinicalSummaryApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import { PatientClinicalSummary } from '@/pages/PatientProfile/CarePlans/shared/types'

/**
 * Retrieve patient physical function assessment for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPatientClinicalSummary(
  patientId: string
): Promise<PatientClinicalSummary | null> {
  const patientClinicalSummaryApi = usePatientClinicalSummaryApi()
  const clinicalSummary = await patientClinicalSummaryApi.retrieve({
    ids: [patientId],
    metaOptions: { bubbleErrorThrow: true },
  })
  if (!clinicalSummary) {
    return null
  }

  if (patientClinicalSummaryApi.error) {
    const error = patientClinicalSummaryApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient clinical summary`,
      type: NotificationType.WARNING,
      error: `Error fetching patient clinical summary: ${error}`,
    })
  }
  return clinicalSummary
}
