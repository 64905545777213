import { PropType } from 'vue'
import { object, string, InferType, boolean, array } from 'yup'

export const schema = object({
  ecogStatus: string().nullable(),
  iadlsInability: array().of(string().required()).nullable(),
  dmeAtHome: array().of(string().required()).nullable(),
  unableToWalkTwoBlocksOrAFlightOfStairs: boolean().nullable(),
  adlsInability: array().of(string().required()).nullable(),
  unableToLeaveHomeOtherThanMedicalAppts: boolean().nullable(),
  proceduralSupport: array().of(string().required()).nullable(),
  additionalContext: string().nullable(),
})

export type EditPhysicalFunctionAssessmentForm = InferType<typeof schema>

export const editPhysicalFunctionAssessmentProps = {
  patientId: {
    type: String,
    required: true,
  },
  physicalFunctionAssessmentId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditPhysicalFunctionAssessmentForm>>,
    required: true,
  },
} as const
