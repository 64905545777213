import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useRadiationApi, useSurgeryApi, useTreatmentApi } from './shared/store'
import { Radiation, Surgery, Treatment } from './shared/types'

/**
 * Retrieve cancer treatments for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param pageLength
 * @param pageNumber
 */
export async function getPatientTreatmentWithParts(
  cancerDxId: string,
  pageLength: number,
  pageNumber: number
): Promise<Treatment | null> {
  const treatmentApi = useTreatmentApi()
  const response = await treatmentApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      parts: ['cancer_diagnosis', 'oncology_systematic_therapy_reference'],
      page_length: pageLength,
      page_number: pageNumber,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (treatmentApi.error) {
    const error = treatmentApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient treatment`,
      type: NotificationType.WARNING,
      error: `Error fetching patient treatment: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve surgery for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param pageLength
 * @param pageNumber
 */
export async function getPatientSurgeryWithParts(
  cancerDxId: string,
  pageLength: number,
  pageNumber: number
): Promise<Surgery | null> {
  const surgeryApi = useSurgeryApi()
  const response = await surgeryApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      parts: ['cancer_diagnosis', 'surgical_procedure_reference'],
      page_length: pageLength,
      page_number: pageNumber,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (surgeryApi.error) {
    const error = surgeryApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient surgery`,
      type: NotificationType.WARNING,
      error: `Error fetching patient surgery: ${error}`,
    })
  }
  return response.data
}

/**
 * Retrieve radiation for a single patient.
 * Returns null if no data is returned.
 * @param cancerDxId
 * @param pageLength
 * @param pageNumber
 */
export async function getPatientRadiationWithParts(
  cancerDxId: string,
  pageLength: number,
  pageNumber: number
): Promise<Radiation | null> {
  const radiationApi = useRadiationApi()
  const response = await radiationApi.list({
    params: {
      filter_cancer_diagnosis_ids: [cancerDxId],
      parts: ['cancer_diagnosis'],
      page_length: pageLength,
      page_number: pageNumber,
    },
  })
  if (!response?.data?.length) {
    return null
  }

  if (radiationApi.error) {
    const error = radiationApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient radiation`,
      type: NotificationType.WARNING,
      error: `Error fetching patient radiation: ${error}`,
    })
  }
  return response.data
}
