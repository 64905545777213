import { PropType } from 'vue'
import { object, string, InferType, array, date } from 'yup'

// edit modal schema
export const schema = object({
  diagnosisDate: date().nullable(),
  otherOncologyDxType: string().nullable(),
  oncologyDiagnosisRefId: string().nullable(),
  oncologyDiagnosisCategoryRefId: string().nullable(),
  oncologySpreadRefIds: array().of(string().required()).nullable(),
  additionalContext: string().nullable(),
})

export type EditCancerDetailsForm = InferType<typeof schema>

export const editCancerDetailsProps = {
  patientId: {
    type: String,
    required: true,
  },
  cancerDetailsId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditCancerDetailsForm>>,
    required: true,
  },
} as const
