<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Medical Team"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <div class="grid grid-cols-2 gap-4">
        <div class="">
          <TDatePicker
            name="lastOncologyAppt"
            label="Last Oncology Appointment"
            class="w-full my-2"
            :is-datetime="false"
            :model-value="initialData.lastOncologyAppt ?? null"
          />
          <TInputText
            name="oncologistName"
            label="Oncologist Name"
            data-testid="oncologist-name"
            class="w-full my-2"
            :model-value="initialData.oncologistName ?? undefined"
          />
          <TInputText
            name="radiationOncologistName"
            label="Radiation Oncologist Name"
            data-testid="radiation-oncologist-name"
            class="w-full my-2"
            :model-value="initialData.radiationOncologistName ?? undefined"
          />
          <TInputText
            name="primaryCareName"
            label="Primary Care Name"
            data-testid="primary-care-name"
            class="w-full my-2"
            option-label="label"
            option-value="value"
            :model-value="initialData.primaryCareName ?? undefined"
          />
        </div>
        <div>
          <TDatePicker
            name="nextOncologyAppt"
            label="Next Oncology Appointment"
            class="w-full my-2"
            :is-datetime="false"
            :model-value="initialData.nextOncologyAppt ?? null"
          />

          <TInputText
            name="surgeonName"
            label="Surgeon Name"
            data-testid="surgeon-name"
            class="w-full my-2"
            :multiple="true"
            :show-clear="false"
            :model-value="initialData.surgeonName ?? undefined"
          />
          <TInputText
            name="palliativeMedicineName"
            label="Palliative Medicine Name"
            data-testid="palliative-medicine-name"
            class="w-full my-2"
            :model-value="initialData.palliativeMedicineName ?? undefined"
          />
        </div>
      </div>

      <TTextarea
        label="Additional Context"
        name="additionalContext"
        :model-value="initialData.additionalContext ?? ''"
      />

      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="medical-team-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="medical-team-save"
          label="Save"
          @click="onSubmit"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'

import { defineComponent } from 'vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editMedicalTeamProps } from './types'

export default defineComponent({
  components: {
    TDatePicker,
    TMPrimaryButton,
    TModal,
    TTextarea,
    TInputText,
    TMSecondaryButton,
  },
  props: editMedicalTeamProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
