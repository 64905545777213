import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { capitalize } from 'lodash'
import { useForm } from 'vee-validate'
import { ExtractPropTypes, ref } from 'vue'
import { booleanOptions } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'
import { checkForEmptyArr } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/utils'
import { Anticoagulation, SedatingMedications } from '../shared/types'
import { createPatientFallRisk, updatePatientFallRisk } from './queries'
import { editFallRiskProps, schema, EditFallRiskForm } from './types'

type PropsType = ExtractPropTypes<typeof editFallRiskProps>

/**
 * Set up the FallRisk component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  const anticoagulationOptions = Object.values(Anticoagulation)
    .map((val) => {
      return { value: val, label: capitalize(val) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
  const sedatingMedicationsOptions = Object.values(SedatingMedications)
    .map((val) => {
      return { value: val, label: capitalize(val) }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const { handleSubmit } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(schema),
  })

  const showSedatingOtherInput = ref(
    !!props.initialData.sedatingMedications?.includes(SedatingMedications.OTHER)
  )
  const showAnticoagulationOtherInput = ref(
    !!props.initialData.anticoagulation?.includes(Anticoagulation.OTHER)
  )

  /**
   *
   * @param selected
   * update showSedatingOtherInput value based on selected option in dropdown
   */
  function setShowOtherSedatingMedication(selected: string[] | undefined) {
    showSedatingOtherInput.value = !!selected?.includes(
      SedatingMedications.OTHER
    )
  }

  /**
   *
   * @param selected
   * update showAnticoagulationOtherInput value based on selected option in dropdown
   */
  function setShowOtherAnticoagulation(selected: string[] | undefined) {
    showAnticoagulationOtherInput.value = !!selected?.includes(
      Anticoagulation.OTHER
    )
  }

  /**
   *
   * @param values
   * update existing fall risk
   * or create new one if there is none
   */
  async function savePatientFallRisk(values: EditFallRiskForm) {
    values.anticoagulation = checkForEmptyArr(values.anticoagulation)
    values.sedatingMedications = checkForEmptyArr(values.sedatingMedications)
    if (props.fallRiskId) {
      await updatePatientFallRisk(props.fallRiskId, values)
    } else {
      await createPatientFallRisk(props.patientId, values)
    }

    context.emit('refetch')
    close()
  }

  const onSubmit = handleSubmit(savePatientFallRisk)

  return {
    ModalSize,
    // dropdown options
    anticoagulationOptions,
    sedatingMedicationsOptions,
    booleanOptions,
    // actions
    close,
    onSubmit,
    // conditional input render
    showSedatingOtherInput,
    showAnticoagulationOtherInput,
    setShowOtherAnticoagulation,
    setShowOtherSedatingMedication,
  }
}
