import { PropType } from 'vue'
import { object, string, InferType, date } from 'yup'

export const schema = object({
  lastOncologyAppt: date()
    .nullable()
    .when(
      'nextOncologyAppt',
      (nextOncologyAppt: any[], schema: { [k: string]: any }) => {
        return nextOncologyAppt[0]
          ? schema.required(
              'Last oncology appointment is required if there is a next oncology appointment.'
            )
          : schema
      }
    )
    .test(
      'is-before-next-appt',
      'Last oncology appointment must not be after the next oncology appointment.',
      function (lastOncologyAppt) {
        const { nextOncologyAppt } = this.parent
        return nextOncologyAppt && lastOncologyAppt
          ? lastOncologyAppt <= nextOncologyAppt
          : true
      }
    ),
  nextOncologyAppt: date().nullable(),
  oncologistName: string().nullable(),
  surgeonName: string().nullable(),
  radiationOncologistName: string().nullable(),
  palliativeMedicineName: string().nullable(),
  primaryCareName: string().nullable(),
  additionalContext: string().nullable(),
})

export type EditMedicalTeamForm = InferType<typeof schema>

export const editMedicalTeamProps = {
  patientId: {
    type: String,
    required: true,
  },
  patientMedTeamId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditMedicalTeamForm>>,
    required: true,
  },
} as const
