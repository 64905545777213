import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useCarePlanTransitionsOfCareApi } from './shared/store'
import { CarePlanTransitionsOfCare } from './shared/types'

/**
 * Retrieve the care plan transitions of care for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getCarePlanTransitionsOfCare(
  patientId: string
): Promise<CarePlanTransitionsOfCare | null> {
  const carePlanTransitionsOfCareApi = useCarePlanTransitionsOfCareApi()
  const carePlanTransitionsOfCareResponse =
    await carePlanTransitionsOfCareApi.list({
      params: {
        filter_patient_ids: [patientId],
      },
    })
  if (!carePlanTransitionsOfCareResponse?.data?.length) {
    return null
  }

  if (carePlanTransitionsOfCareApi.error) {
    const error = carePlanTransitionsOfCareApi.error
    useNotificationStore().setNotification({
      message: `Error fetching care plan transitions of care`,
      type: NotificationType.DANGER,
      error: `Error fetching care plan transitions of care: ${error}`,
    })
  }
  return carePlanTransitionsOfCareResponse.data[0]
}
