import apiStore from '@/legacy/store/modules/apiBuilder'
import {
  Radiation,
  Surgery,
  Treatment,
  TreatmentField,
  TreatmentFieldsDataState,
} from './types'

/**
 *
 * @param data
 * @param total
 * @param field
 */
function transform(
  data: Treatment[] | Surgery[] | Radiation[],
  total: number,
  field: TreatmentField
): Partial<TreatmentFieldsDataState> {
  let newData
  if (field === TreatmentField.TREATMENT) {
    const currentTreatments = useTreatmentApi().data ?? []
    newData = [...currentTreatments, ...data]
  }
  if (field === TreatmentField.SURGERY) {
    const currentSurgeries = useSurgeryApi().data ?? []
    newData = [...currentSurgeries, ...data]
  }
  if (field === TreatmentField.RADIATION) {
    const currentRadiationDates = useRadiationApi().data ?? []
    newData = [...currentRadiationDates, ...data]
  }
  return { data: newData, queryMetadata: { total } }
}
export const useTreatmentApi = apiStore<Treatment>(
  'treatmentApi',
  '/api/care_plan/patient_treatments',
  {
    transformData: (d: {
      data: Treatment[]
      queryMetadata: { total: number }
    }) => transform(d.data, d.queryMetadata.total, TreatmentField.TREATMENT),
  }
)

export const useSurgeryApi = apiStore<Surgery>(
  'surgeryApi',
  '/api/care_plan/patient_surgeries',
  {
    transformData: (d: { data: Surgery[]; queryMetadata: { total: number } }) =>
      transform(d.data, d.queryMetadata.total, TreatmentField.SURGERY),
  }
)

export const useRadiationApi = apiStore<Radiation>(
  'radiationApi',
  '/api/care_plan/patient_radiations',
  {
    transformData: (d: {
      data: Radiation[]
      queryMetadata: { total: number }
    }) => transform(d.data, d.queryMetadata.total, TreatmentField.RADIATION),
  }
)
