<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5"
  >
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <label class="t-grid-item">ECOG Status</label>
          <div class="t-grid-item">
            {{ ecogStatusDisplay }}
          </div>
          <label class="t-grid-item"
            >Unable to Perform the Following IADLs</label
          >
          <div class="t-grid-item">
            {{ unableToPerformTheFollowingIADLsDisplay }}
          </div>
          <label class="t-grid-item"
            >Unable to Perform the Following ADLs</label
          >
          <div class="t-grid-item">
            {{ unableToPerformTheFollowingADLsDisplay }}
          </div>
          <label class="t-grid-item">Homebound</label>
          <div class="t-grid-item">
            {{ homeboundDisplay }}
          </div>
          <label class="t-grid-item">DME</label>
          <div class="t-grid-item">
            {{ dmeDisplay }}
          </div>
          <label class="t-grid-item">Procedural Support</label>
          <div class="t-grid-item">
            {{ proceduralSupportDisplay }}
          </div>
          <label class="t-grid-item"
            >Walks 2 Blocks or 1 Flight of Stairs</label
          >
          <div class="t-grid-item">
            {{ walksTwoBlocksOrOneFlightOfStairsDisplay }}
          </div>
          <label class="t-grid-item">Additional Information</label>
          <div class="t-grid-item">
            {{ additionalInformationDisplay }}
          </div>
        </div>
      </div>
      <div v-tooltip.left="'Edit physical function assessment'">
        <TMSecondaryButton
          icon="pencil"
          class="w-9 h-10 p-9 ml-3 p-4"
          @click="togglePhysicalFunctionAssessmentEdit"
        />
      </div>
    </div>
  </div>
  <EditPhysicalFunctionAssessment
    v-if="showEditModal"
    :patient-id="patientId"
    :initial-data="editModalInitialData"
    :physical-function-assessment-id="physicalFunctionAssessmentId"
    @refetch="refetchPhysicalFunctionAssessment"
    @close="togglePhysicalFunctionAssessmentEdit"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'
import EditPhysicalFunctionAssessment from './EditPhysicalFunctionAssessment/EditPhysicalFunctionAssessment.vue'

export default defineComponent({
  components: {
    EditPhysicalFunctionAssessment,
    TMSecondaryButton,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
