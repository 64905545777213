import apiStore from '@/legacy/store/modules/apiBuilder'
import { MedicalCondition } from '@/pages/PatientProfile/CarePlans/shared/types'
import {
  MapMedicalAttrsMedicalConditions,
  MedicalConditionAttributeReference,
} from './types'

export const useMedicalConditionsWithAttrsApi = apiStore<MedicalCondition>(
  'medicalConditionsWithAttrsApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)

export const useMedicalConditionsWithoutAttrsApi = apiStore<MedicalCondition>(
  'medicalConditionsWithoutAttrsApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)

export const useMapMedicalAttrsMedicalConditionsApi =
  apiStore<MapMedicalAttrsMedicalConditions>(
    'mapMedicalAttrsMedicalConditionsApi',
    '/api/map_med_condition_attrs_ref_med_conditions_ref',
    {
      transformData: (d: { data: MapMedicalAttrsMedicalConditions[] }) => d,
    }
  )

export const useMedicalConditionAttrsRefApi =
  apiStore<MedicalConditionAttributeReference>(
    'medicalConditionAttrsRefApi',
    '/api/medical_condition_attributes/reference',
    {
      transformData: (d: { data: MedicalConditionAttributeReference[] }) => d,
    }
  )
