import { requiredInject } from '@thyme/libs/src/vue/inject'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { formatDateTime } from '@/legacy/libs/format'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { EditMedicalTeamForm } from './EditMedicalTeam/types'
import { getPatientMedicalTeam } from './queries'
import { useMedicalTeamApi } from './shared/store'

// Defined only for convenience and use in testing.
export type MedicalTeamViewModel = {
  patientId: Ref<string>
  patientMedTeamId: Ref<string | undefined>
  lastOncologyAppt: Ref<string | null>
  nextOncologyAppt: Ref<string | null>
  oncologistName: Ref<string>
  surgeonName: Ref<string>
  radiationOncologistName: Ref<string>
  palliativeMedicineName: Ref<string>
  primaryCareName: Ref<string>
  additionalContext: Ref<string>
  showEditModal: Ref<boolean>
  editModalInitialData: Ref<Partial<EditMedicalTeamForm>>
  toggleMedicalTeamEdit: () => void
  refetchMedicalTeam: () => Promise<void>
}

/**
 *
 *
 */
export function setup(): MedicalTeamViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)
  const { data: medTeamData } = storeToRefs(useMedicalTeamApi())
  const medicalTeam = computed(() => medTeamData.value?.[0] ?? null)
  const patientMedTeamId = computed(() => medicalTeam.value?.patientMedTeamId)

  const lastOncologyAppt = computed(() => {
    const lastOncAppt = medicalTeam.value?.lastOncologyAppt
    if (lastOncAppt === undefined || lastOncAppt === null) {
      return '-'
    }
    return formatDateTime(stringToDateTime(lastOncAppt))
  })

  const nextOncologyAppt = computed(() => {
    const nextOncologyAppt = medicalTeam.value?.nextOncologyAppt
    if (nextOncologyAppt === undefined || nextOncologyAppt === null) {
      return '-'
    }
    return formatDateTime(stringToDateTime(nextOncologyAppt))
  })

  const oncologistName = computed(() => {
    const oncName = medicalTeam.value?.oncologistName
    if (oncName === undefined || oncName === null) {
      return '-'
    }
    return oncName
  })

  const surgeonName = computed(() => {
    const surgName = medicalTeam.value?.surgeonName
    if (surgName === undefined || surgName === null) {
      return '-'
    }
    return surgName
  })

  const radiationOncologistName = computed(() => {
    const radiationOncName = medicalTeam.value?.radiationOncologistName
    if (radiationOncName === undefined || radiationOncName === null) {
      return '-'
    }
    return radiationOncName
  })

  const palliativeMedicineName = computed(() => {
    const palliativeMedName = medicalTeam.value?.palliativeMedicineName
    if (palliativeMedName === undefined || palliativeMedName === null) {
      return '-'
    }
    return palliativeMedName
  })

  const primaryCareName = computed(() => {
    const pcpName = medicalTeam.value?.primaryCareName
    if (pcpName === undefined || pcpName === null) {
      return '-'
    }
    return pcpName
  })

  const additionalContext = computed(
    () => medicalTeam.value?.additionalContext ?? '-'
  )

  const editModalInitialData = computed(() => ({
    lastOncologyAppt: medicalTeam.value?.lastOncologyAppt
      ? new Date(medicalTeam.value?.lastOncologyAppt)
      : null,
    oncologistName: medicalTeam.value?.oncologistName,
    radiationOncologistName: medicalTeam.value?.radiationOncologistName,
    palliativeMedicineName: medicalTeam.value?.palliativeMedicineName,
    nextOncologyAppt: medicalTeam.value?.nextOncologyAppt
      ? new Date(medicalTeam.value?.nextOncologyAppt)
      : null,
    primaryCareName: medicalTeam.value?.primaryCareName,
    surgeonName: medicalTeam.value?.surgeonName,
    additionalContext: medicalTeam.value?.additionalContext,
  }))

  const refetchMedicalTeam = async () => {
    await getPatientMedicalTeam(patientId.value)
  }

  /**
   * open/close edit modal
   */
  function toggleMedicalTeamEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(async () => {
    await getPatientMedicalTeam(patientId.value)
  })

  return {
    patientId,
    patientMedTeamId,
    // display values
    lastOncologyAppt,
    nextOncologyAppt,
    oncologistName,
    surgeonName,
    radiationOncologistName,
    palliativeMedicineName,
    primaryCareName,
    additionalContext,
    // medical team edit modal
    toggleMedicalTeamEdit,
    showEditModal,
    editModalInitialData,
    refetchMedicalTeam,
  }
}
