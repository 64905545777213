import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { ExtractPropTypes } from 'vue'
import { createMedicalTeam, updateMedicalTeam } from './queries'
import { editMedicalTeamProps, schema, EditMedicalTeamForm } from './types'

type PropsType = ExtractPropTypes<typeof editMedicalTeamProps>

/**
 * Set up the EditMedicalTeam component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  const { handleSubmit } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(schema),
  })

  /**
   *
   * @param values
   * update existing medical team
   * or create new one if there is none
   */
  async function saveMedicalTeam(values: EditMedicalTeamForm) {
    if (props.patientMedTeamId) {
      await updateMedicalTeam(props.patientMedTeamId, values)
    } else {
      await createMedicalTeam(props.patientId, values)
    }

    context.emit('refetch')
    close()
  }

  const onSubmit = handleSubmit(saveMedicalTeam)

  return {
    ModalSize,
    // actions
    close,
    onSubmit,
  }
}
