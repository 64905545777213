import { arrayToStringDisplay } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/utils'
import { MedicalConditionAttributeReference } from './types'

/**
 *
 * @param attributeId
 * @param medicalConditionAttrRefs
 */
export function getAttributeDescription(
  attributeId: string,
  medicalConditionAttrRefs: MedicalConditionAttributeReference[] | null
) {
  if (!medicalConditionAttrRefs || !medicalConditionAttrRefs.length) {
    return '-'
  }
  const attr = medicalConditionAttrRefs.find(
    (attr: MedicalConditionAttributeReference) =>
      attr.medicalConditionAttributeId === attributeId
  )
  return attr ? attr.description : '-'
}

/**
 *
 * @param attributeIds
 * @param medicalConditionAttrRefs
 */
export function formatAttributesDisplay(
  attributeIds: string[],
  medicalConditionAttrRefs: MedicalConditionAttributeReference[] | null
) {
  const attrDescriptions = attributeIds.map((attrId) =>
    getAttributeDescription(attrId, medicalConditionAttrRefs)
  )
  return arrayToStringDisplay(attrDescriptions)
}
