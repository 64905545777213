import { DateTime } from 'luxon'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { stringToDateTime } from '@/legacy/libs/date'
import { usePatientClinicalSummaryApi } from '@/pages/PatientProfile/CarePlans/shared/store'
import {
  getPatientRadiationWithParts,
  getPatientSurgeryWithParts,
  getPatientTreatmentWithParts,
} from './queries'
import { useRadiationApi, useSurgeryApi, useTreatmentApi } from './shared/store'
import { TreatmentField } from './shared/types'

// Defined only for convenience and use in testing.
export type PatientTreatmentViewModel = {
  treatmentDisplay: Ref<string[]>
  radiationDisplay: Ref<string[]>
  surgeryDisplay: Ref<string[]>
  showMoreTreatmentsButton: Ref<boolean>
  showMoreSurgeriesButton: Ref<boolean>
  showMoreRadiationDatesButton: Ref<boolean>
  TreatmentField: typeof TreatmentField
  loadMore: (field: string) => void
}

/**
 *
 *
 */
export function setup(): PatientTreatmentViewModel {
  const { datum: clinicalSummary } = storeToRefs(usePatientClinicalSummaryApi())
  const { data: treatments, queryMetadata: treatmentMetadata } = storeToRefs(
    useTreatmentApi()
  )
  const { data: surgeries, queryMetadata: surgeryMetadata } = storeToRefs(
    useSurgeryApi()
  )
  const { data: radiation, queryMetadata: radiationMetadata } = storeToRefs(
    useRadiationApi()
  )
  const cancerDetailsId = computed(
    () => clinicalSummary.value?.cancerDiagnosis.cancerDiagnosisId ?? null
  )

  const perPage = 3
  const pageNumber = ref(1)

  const totalTreatmentCount = computed(
    () => treatmentMetadata.value?.total ?? 0
  )
  const totalSurgeryCount = computed(() => surgeryMetadata.value?.total ?? 0)
  const totalRadiationCount = computed(
    () => radiationMetadata.value?.total ?? 0
  )

  const treatmentDisplay = computed(() => {
    const patientTreatments = treatments.value ?? null
    if (patientTreatments && patientTreatments.length) {
      // sort treatments alphabetically
      const sortedTreatments = patientTreatments.sort((a, b) =>
        a.oncologySystematicTherapyReference.agent.localeCompare(
          b.oncologySystematicTherapyReference.agent
        )
      )
      // format treatment agent and brand into string array for display
      const formatted = sortedTreatments.map((treatment) => {
        if (treatment.otherTreatmentType) {
          return treatment.otherTreatmentType
        }
        const oncSystematicTherapyRef =
          treatment.oncologySystematicTherapyReference
        if (oncSystematicTherapyRef) {
          return `
            ${oncSystematicTherapyRef.agent} (current) •
            Brands: ${oncSystematicTherapyRef.brandNames.join(', ')}
          `
        }
        return ''
      })
      return formatted
    }
    return ['-']
  })

  const surgeryDisplay = computed(() => {
    const patientSurgeries = surgeries.value ?? null
    if (patientSurgeries) {
      return patientSurgeries.map((surgery) => {
        return `${
          surgery.surgicalProcedureReference.description
        } (${stringToDateTime(surgery.surgeryDate).toLocaleString(
          DateTime.DATE_SHORT
        )})`
      })
    }
    return ['-']
  })

  const radiationDisplay = computed(() => {
    const patientRadiation = radiation.value ?? null
    if (patientRadiation) {
      return patientRadiation.map((rad) =>
        stringToDateTime(rad.radiationDate).toLocaleString(DateTime.DATE_SHORT)
      )
    }
    return ['-']
  })

  const showMoreTreatmentsButton = computed(
    () => treatmentDisplay.value.length < totalTreatmentCount.value
  )
  const showMoreSurgeriesButton = computed(
    () => surgeryDisplay.value.length < totalSurgeryCount.value
  )
  const showMoreRadiationDatesButton = computed(
    () => radiationDisplay.value.length < totalRadiationCount.value
  )

  // fetch 3 more treatments, surgeries, or radiation dates
  const loadMore = async (field: string) => {
    if (cancerDetailsId.value) {
      pageNumber.value = pageNumber.value + 1
      if (field === TreatmentField.TREATMENT) {
        await getPatientTreatmentWithParts(
          cancerDetailsId.value,
          perPage,
          pageNumber.value
        )
      } else if (field === TreatmentField.SURGERY) {
        await getPatientSurgeryWithParts(
          cancerDetailsId.value,
          perPage,
          pageNumber.value
        )
      } else if (field === TreatmentField.RADIATION) {
        await getPatientRadiationWithParts(
          cancerDetailsId.value,
          perPage,
          pageNumber.value
        )
      }
    }
  }

  onBeforeMount(async () => {
    if (cancerDetailsId.value) {
      await getPatientTreatmentWithParts(
        cancerDetailsId.value,
        perPage,
        pageNumber.value
      )
      await getPatientSurgeryWithParts(
        cancerDetailsId.value,
        perPage,
        pageNumber.value
      )
      await getPatientRadiationWithParts(
        cancerDetailsId.value,
        perPage,
        pageNumber.value
      )
    }
  })

  return {
    loadMore,
    // enum
    TreatmentField,
    // display values
    treatmentDisplay,
    radiationDisplay,
    surgeryDisplay,
    // show more buttons
    showMoreTreatmentsButton,
    showMoreSurgeriesButton,
    showMoreRadiationDatesButton,
  }
}
