import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { usePhysicalFunctionAssessmentApi } from './shared/store'
import { PhysicalFunctionAssessment } from './shared/types'

/**
 * Retrieve patient physical function assessment for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPhysicalFunctionAssessment(
  patientId: string
): Promise<PhysicalFunctionAssessment | null> {
  const physicalFunctionAssessmentApi = usePhysicalFunctionAssessmentApi()
  const pfaResponse = await physicalFunctionAssessmentApi.list({
    params: {
      filter_patient_ids: [patientId],
    },
  })
  if (!pfaResponse?.data?.length) {
    return null
  }

  if (physicalFunctionAssessmentApi.error) {
    const error = physicalFunctionAssessmentApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient physical function assessment`,
      type: NotificationType.WARNING,
      error: `Error fetching patient physical function assessment: ${error}`,
    })
  }
  return pfaResponse.data[0]
}
