import apiStore from '@/legacy/store/modules/apiBuilder'
import { FallRisk } from './types'

export const useFallRiskApi = apiStore<FallRisk>(
  'fallRiskApi',
  '/api/care_plan/fall_risk',
  {
    transformData: (d: { data: FallRisk[] }) => d,
  }
)
