import { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'
import { CancerDiagnosis } from '@/pages/PatientProfile/CarePlans/shared/types'

export type Treatment = {
  patientTreatmentId: UUID
  otherTreatmentType: string
  oncologySystematicTherapyRefId: UUID
  cancerDiagnosisId: UUID
  treatmentEndDate: string
  treatmentStartDate: string
  cancerDiagnosis: CancerDiagnosis
  oncologySystematicTherapyReference: OncologySystematicTherapyReference
} & Timestamps

export type OncologySystematicTherapyReference = {
  oncologySystematicTherapyRefId: UUID
  agent: string
  brandNames: string[]
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
} & Timestamps

export type Surgery = {
  surgicalProcedureRefId: UUID
  otherSurgeryType: string
  patientSurgeryId: UUID
  cancerDiagnosisId: UUID
  surgeryDate: string
  cancerDiagnosis: CancerDiagnosis
  surgicalProcedureReference: SurgicalProcedureReference
} & Timestamps

export type SurgicalProcedureReference = {
  surgicalProcedureRefId: UUID
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: string
} & Timestamps

export type Radiation = {
  patientRadiationId: UUID
  cancerDiagnosisId: UUID
  radiationDate: string
  cancerDiagnosis: CancerDiagnosis
} & Timestamps

// ENUMS
export enum TreatmentField {
  TREATMENT = 'treatment',
  SURGERY = 'surgery',
  RADIATION = 'radiation',
}

// INTERFACES
export interface TreatmentFieldsDataState {
  isLoading: boolean
  error: Error | null
  data: Treatment[] | any
  isLoaded: boolean
  queryMetadata: {
    total: number
  } | null
}
