<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Member Details"
      :allow-overflow="true"
      :size="ModalSize.SM"
      @close="close"
    >
      <TInputText
        label="Preferred name"
        name="preferredName"
        :model-value="initialData.preferredName ?? undefined"
      />

      <TMDropdownField
        name="language"
        label="Preferred language"
        class="w-full"
        :options="languageOptions"
        option-label="label"
        option-value="value"
        :model-value="initialData.language ?? undefined"
      />

      <TDatePicker
        name="dob"
        :model-value="initialData.dob"
        label="DOB"
        class="flex-grow"
        :is-datetime="false"
        :clearable="false"
      />

      <TDatePicker
        name="dod"
        :model-value="initialData.dod"
        label="DOD"
        class="flex-grow"
        :is-datetime="false"
        :clearable="false"
      />

      <TMDropdownField
        name="gender"
        label="Gender"
        :model-value="initialData.gender ?? undefined"
        class="w-full"
        input-id="patient-gender"
        option-label="label"
        option-value="value"
        :options="genderOptions"
      />

      <div class="space-y-2">
        <TMDropdownField
          name="carePodId"
          label="Care Pod"
          :model-value="initialData.carePodId ?? undefined"
          class="w-full mb-2"
          input-id="patient-care-pod"
          option-label="name"
          option-value="carePodId"
          :options="carePods"
          :disabled="!canEditPatientCarePod"
        />
      </div>

      <div v-if="enablePatientContracts" class="space-y-2">
        <TMDropdownField
          name="contractingEntityId"
          label="Contracting Entity"
          :model-value="initialData.contractingEntityId ?? undefined"
          class="w-full mb-2"
          input-id="patient-contracting-entity"
          option-label="displayName"
          option-value="contractingEntityId"
          :options="contractingEntities"
          :disabled="!canEditPatientContract"
        />

        <TInputText
          label="Contract ID"
          name="contractExternalId"
          :model-value="initialData.contractExternalId ?? undefined"
          :disabled="!canEditPatientContract"
        />
      </div>
      <template #actions>
        <TMPrimaryButton
          name="patient-details-save"
          label="Confirm"
          @click="onSubmit"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'

import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'

import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editPatientDetailsProps } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMDropdownField,
    TInputText,
    TModal,
    TDatePicker,
  },
  props: editPatientDetailsProps,
  emits: ['close', 'refresh-contracting-chip'],
  setup,
})
</script>
