<template>
  <div v-if="icon === AllowedIcons.HighPriority" class="inline-block">
    <img
      :class="classes"
      :src="HighPriority"
      alt="Red high priority subtask icon."
    />
  </div>
  <div v-else-if="icon === AllowedIcons.UrgentPriority" class="inline-block">
    <img
      :class="classes"
      :src="UrgentPriority"
      alt="Pink urgent priority subtask icon."
    />
  </div>
  <div v-else-if="icon === AllowedIcons.RoutinePriority" class="inline-block">
    <img
      :class="classes"
      :src="RoutinePriority"
      alt="Purple routine priority subtask icon."
    />
  </div>
  <div v-else-if="icon === AllowedIcons.OptionalPriority" class="inline-block">
    <img
      :class="classes"
      :src="OptionalPriority"
      alt="Blue optional priority subtask icon."
    />
  </div>

  <div v-else-if="icon === AllowedIcons.EmptyPriority" class="inline-block">
    <img
      :class="classes"
      :src="EmptyPriority"
      alt="Empty priority subtask icon."
    />
  </div>
  <Icon
    v-else-if="icon && AllowedIcons[icon as keyof typeof AllowedIcons] && !hasImportedSVG"
    :class="classes"
    :icon="AllowedIcons[icon as keyof typeof AllowedIcons]"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  />
  <Icon
    v-else-if="icon && !hasImportedSVG"
    :class="classes"
    :icon="icon"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  />
</template>

<script lang="ts" class="inline-block">
import { Icon } from '@iconify/vue'
import {
  iconProp,
  iconPositionProp,
  AllowedIcons,
  SVGFileIconName,
} from '@thyme/nashville/src/types/icons'
import { sizeProp, colorProp } from '@thyme/nashville/src/types/shared'
import { tokenizedComponentClasses } from '@thyme/nashville/src/utils/designTokens'
import upperFirst from 'lodash/upperFirst'

import { computed, defineComponent, PropType } from 'vue'

import EmptyPriority from './svgs/priorities/empty.svg'
import HighPriority from './svgs/priorities/high.svg'
import OptionalPriority from './svgs/priorities/optional.svg'
import RoutinePriority from './svgs/priorities/routine.svg'
import UrgentPriority from './svgs/priorities/urgent.svg'

export default defineComponent({
  components: { Icon },
  props: {
    iconClasses: {
      type: Array as PropType<string[]>,
      default: null,
    },
    ...iconProp,
    ...sizeProp,
    ...iconPositionProp,
    ...colorProp,
  },
  emits: ['click'],
  setup(props) {
    const tokenClasses = tokenizedComponentClasses(
      'icon',
      props,
      Object.keys(sizeProp)
    )
    const buttonTokenClasses = tokenizedComponentClasses(
      'button-icon',
      props,
      Object.keys(iconPositionProp)
    )

    const chipTokenClasses = tokenizedComponentClasses(
      'chip-icon',
      props,
      Object.keys(iconPositionProp)
    )

    const isLoadingIcon = computed(
      () => props.icon === AllowedIcons.loading || props.icon === 'loading'
    )

    const classes = computed(() => [
      'p-icon',
      isLoadingIcon.value ? 'pi-spin' : '',
      ...tokenClasses.value,
      ...buttonTokenClasses.value,
      ...chipTokenClasses.value,
      ...(props.iconClasses ? props.iconClasses : ''),
      props.color ? `text-${props.color}` : '',
    ])

    const hasImportedSVG = computed(() => {
      const onlyImportedSVGs = { ...SVGFileIconName }
      const iconKey = upperFirst(props.icon) as keyof typeof SVGFileIconName
      return props.icon && onlyImportedSVGs[iconKey]
    })

    return {
      hasImportedSVG,
      AllowedIcons,
      EmptyPriority,
      HighPriority,
      classes,
      OptionalPriority,
      RoutinePriority,
      UrgentPriority,
      SVGFileIconName,
    }
  },
})
</script>
<style lang="scss" scoped>
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.pi-spin {
  animation: fa-spin 2s linear infinite;
}
.p-icon {
  @apply text-base;
  min-height: 1.4rem;
  min-width: 1.4rem;
  margin: 0;
  padding: 0;
  &-xs {
    @apply text-xs;
    min-height: 1rem;
    min-width: 1rem;
  }
  &-sm {
    @apply text-sm;
    min-height: 1.25rem;
    min-width: 1.25rem;
  }
  &-lg {
    @apply text-lg;
    min-height: 1.75rem;
    min-width: 1.75rem;
  }
  &-xl {
    @apply text-xl;
    min-height: 2.25rem;
    min-width: 2.25rem;
  }
}
</style>
