import apiStore from '@/legacy/store/modules/apiBuilder'
import { MedicalTeam } from './types'

export const useMedicalTeamApi = apiStore<MedicalTeam>(
  'medicalTeamApi',
  '/api/care_plan/cancer_med_team',
  {
    transformData: (d: { data: MedicalTeam[] }) => d,
  }
)
