import { datadogRum } from '@datadog/browser-rum'
import concat from 'lodash/concat'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import {
  PhoneDropdownValue,
  patientAndContactEntities,
  preferredPhoneId,
} from '@/legacy/components/patient/communicationV2/dropdowns/lib/phoneDropdown'
import { createDisposition } from '@/legacy/components/patient/communicationV2/lib/callDispositions'
import { updateCommunication } from '@/legacy/components/patient/communicationV2/lib/communications'
import { dial } from '@/legacy/libs/call'
import { enumValueToKey } from '@/legacy/libs/enum'
import { toIdMap } from '@/legacy/libs/store'
import {
  createDefaultDatetime,
  useCommunicationsStore,
} from '@/legacy/store/modules/communications'
import { useNotificationStore } from '@/legacy/store/modules/notification'
import { usePatientStore } from '@/legacy/store/modules/patient'
import { useProfileStore } from '@/legacy/store/modules/profile'
import {
  CallDirection,
  callDispositionDisplayLookup,
  SpeakingWithType,
} from '@/legacy/types/communications/callDispositions'
import {
  CommunicationType,
  OUTBOUND_CALL_TYPE_POST,
} from '@/legacy/types/communications/communications'
import { MapEntityPhoneNumber } from '@/legacy/types/entities/phoneNumbers'
import { NotificationType } from '@/legacy/types/notifications'
import { Contact } from '@/legacy/types/patients/contacts'

/**
 *
 * @param props
 * @param context
 */
export default function (props: any, context: any) {
  const showPopover = ref()
  const togglePopover = (event: Event) => {
    showPopover.value.toggle(event)
  }

  const { patient, person, entity, phoneNumbers, contacts } = storeToRefs(
    usePatientStore()
  )
  const { selfEntity } = storeToRefs(useProfileStore())

  // Communication fields ----------
  const notes = computed(() => props.communication.notes ?? '')
  const patientIds = computed(
    () => props.communication.patientIds ?? [patient.value?.entityId]
  )

  // Planned call fields ----------
  const communicationId = computed(
    () =>
      props.plannedCall?.communicationId ?? props.communication.communicationId
  )
  const initialCalleeEntityId = computed(
    () => props.plannedCall?.calleeEntityId ?? patient.value?.entityId ?? null
  )
  const initialPhoneNumberId = computed(() => {
    const entityId = initialCalleeEntityId.value
    if (entityId === patient.value?.entityId) {
      return preferredPhoneId(Object.values(phoneNumbers.value ?? {}))
    }
    const contactsArr: Contact[] = Object.values(contacts.value ?? {})

    const entityIdToContactId = contactsArr.reduce(
      (acc: { [key: string]: string }, cur: Contact) => {
        acc[cur.contactEntityId] = cur.contactId
        return acc
      },
      {}
    )
    if (entityId && contacts.value && entityIdToContactId[entityId]) {
      return (
        preferredPhoneId(
          contacts.value[entityIdToContactId[entityId]].contactEntity
            ?.phoneNumbers ?? []
        ) ?? null
      )
    }
    return null
  })

  const dirtySelectedCallee = ref({
    entityId: initialCalleeEntityId.value,
    phoneNumberId: initialPhoneNumberId.value,
  } as PhoneDropdownValue)

  const callOptionEntities = computed(() => {
    if (contacts.value && entity.value && phoneNumbers.value && person.value) {
      return patientAndContactEntities(
        contacts.value,
        entity.value,
        phoneNumbers.value,
        person.value
      )
    }
    return []
  })

  const isDisabled = computed(() => props.plannedCall && !communicationId.value)

  const selectedPhoneNumberId = computed(
    () => dirtySelectedCallee.value.phoneNumberId
  )

  const selectedEntityId = computed(() => dirtySelectedCallee.value.entityId)

  const phoneNumberIdMap = computed(() => {
    if (contacts.value && phoneNumbers.value) {
      const contactArr: Contact[] = Object.values(contacts.value ?? {})
      const contactPhones = contactArr
        .map((c: Contact) => c.contactEntity.phoneNumbers ?? [])
        .flat()

      const combinedPhoneNumbers: MapEntityPhoneNumber[] = concat(
        Object.values(phoneNumbers.value ?? {}),
        contactPhones
      )
      return toIdMap(combinedPhoneNumbers, 'phoneNumberId')
    }
    return {}
  })

  const selectedPhoneNumber = computed(() =>
    selectedPhoneNumberId.value
      ? phoneNumberIdMap.value[selectedPhoneNumberId.value].phoneNumber
      : null
  )

  /**
   *
   * Function that returns call disposition payload.
   */
  function getDispositionPayload() {
    return {
      communicationId: communicationId.value,
      completedByStaffId: selfEntity.value?.entityId,
      direction: CallDirection.outbound,
      speakingWithPersonId: selectedEntityId.value,
      speakingWithType: getCalleeType(selectedEntityId.value ?? null),
      isIdentityVerified: false,
      disposition: enumValueToKey(
        callDispositionDisplayLookup,
        callDispositionDisplayLookup.ANSWERED
      ),
    }
  }

  /**
   *
   * Function that gets communication payload.
   */
  function getCommunicationPayload() {
    return {
      responsibleStaffId: selfEntity.value?.entityId,
      completedDatetime: createDefaultDatetime(),
      patientIds: patientIds.value,
      type: CommunicationType.Call,
      notes: notes.value,
    }
  }

  /**
   *
   * Function that triggers dialing/calling out and creates
   * communication and call disposition.
   * @param manualNumber
   */
  async function generateOutboundCall(manualNumber?: string) {
    const numberToCall = selectedPhoneNumber.value?.phoneNumber ?? manualNumber
    if (numberToCall) {
      await dial({ phoneNumber: numberToCall })
      await createCallDisposition()
    } else {
      alert('No phone number found.')
    }
  }

  /**
   *
   * Function to retrieve callee type.
   * @param calleeId
   */
  function getCalleeType(calleeId: string | null): SpeakingWithType | null {
    if (calleeId === patient.value?.entityId) {
      return SpeakingWithType.member
    }
    if (selectedPhoneNumber.value) {
      return SpeakingWithType.contact
    }
    return null
  }

  /**
   *
   * Function to update selected callee value and emits update
   * back to parent component.
   * @param v
   */
  function updateCallee(v: PhoneDropdownValue) {
    dirtySelectedCallee.value = v
    context.emit('updatePlannedCallee', v.entityId)
  }

  /**
   *
   * Function that directly sets communication type
   * to outbound call disposition.
   */
  function setCommTypeToDisposition() {
    useCommunicationsStore().communicationType = OUTBOUND_CALL_TYPE_POST
  }

  /**
   *
   * Function that creates the communication
   * and its call disposition subtype (outbound).
   */
  async function createCallDisposition() {
    if (selfEntity.value) {
      const dispositionPayload = getDispositionPayload()
      const communicationPayload = getCommunicationPayload()

      const updatedComm = await updateCommunication(
        communicationId.value,
        communicationPayload
      )

      const idToUse = updatedComm?.communicationId ?? communicationId.value

      datadogRum.addAction('createDispositionStart', {
        patientId: patient.value?.entityId,
        communicationId: updatedComm.communicationId,
        staffId: selfEntity.value?.entityId,
        calledFrom: 'CommunicationCall.vue',
      })
      const createdCallDisposition = await createDisposition(
        idToUse,
        dispositionPayload
      )

      if (createdCallDisposition) {
        setCommTypeToDisposition()
      }
    } else {
      useNotificationStore().setNotification({
        message: "Failed to trigger call as current user can't be found.",
        type: NotificationType.DANGER,
      })
    }
  }

  return {
    setCommTypeToDisposition,
    getCalleeType,
    getDispositionPayload,
    getCommunicationPayload,
    togglePopover,
    showPopover,
    contacts,
    selectedPhoneNumber,
    updateCallee,
    generateOutboundCall,
    createCallDisposition,
    isDisabled,
    dirtySelectedCallee,
    callOptionEntities,
  }
}
