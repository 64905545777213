import { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'
import { Domain } from '@/pages/PatientProfile/CarePlans/CarePlan/types'

// TYPES --------------------
export type Goal = {
  goalId: UUID
  memberId: UUID
  goalRefId?: UUID
  freeTextTitle?: string
  details?: string
  timeline?: string
  status: GoalStatus
  sortNumber: number
  domainId: Nullable<UUID>
  goalReference: GoalReference
  domain: Domain
} & Timestamps

export type GoalReference = {
  goalRefId: UUID
  titleDisplay: string
} & Timestamps

export type GoalReferencePayload = {
  titleDisplay: string
}

// ENUMS --------------------
export enum GoalStatus {
  COMPLETED = 'COMPLETED',
  ON_HOLD = 'ON_HOLD',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

// CONSTANTS --------------------
export const MAX_GOALS = 3

export const goalStatuses = [
  { label: 'Current', value: GoalStatus.ACTIVE },
  { label: 'On Hold', value: GoalStatus.ON_HOLD },
  { label: 'Completed', value: GoalStatus.COMPLETED },
  { label: 'Canceled', value: GoalStatus.CANCELLED },
]
