import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { MedicalCondition } from '@/pages/PatientProfile/CarePlans/shared/types'
import { useMedicalConditionsCarePlanApi } from './store'

/**
 * Retrieve sensitive medical conditions for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getSensitiveMedicalConditions(
  patientId: string
): Promise<MedicalCondition[] | null> {
  let medicalConditionsRes
  try {
    const medicalConditionsApi = useMedicalConditionsCarePlanApi()
    medicalConditionsRes = await medicalConditionsApi.list({
      params: {
        filter_patient_ids: [patientId],
        parts: ['medical_condition_reference'],
        filter_is_archived: false,
        filter_is_sensitive: true,
        page_length: 1, //we need to only check if patient has atleast 1 sensitive medical condition
      },
    })
    if (!medicalConditionsRes?.data?.length) {
      return null
    } else {
      return medicalConditionsRes.data
    }
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Error fetching medical conditions for Care Plan view',
      type: NotificationType.WARNING,
      error: `Error fetching medical conditions for Care Plan view: ${err}`,
    })
    return null
  }
}
