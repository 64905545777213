import { requiredInject } from '@thyme/libs/src/vue/inject'
import { camelCase, startCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref, Ref } from 'vue'
import { PATIENT_ID_KEY } from '@/pages/PatientProfile/shared/types'
import { arrayToStringDisplay, formatStringDisplay } from '../shared/utils'
import { EditTransitionsOfCareForm } from './EditTransitionsOfCare/types'
import { getCarePlanTransitionsOfCare } from './queries'
import { useCarePlanTransitionsOfCareApi } from './shared/store'

export type TransitionsOfCareViewModel = {
  patientId: Ref<string>
  transitionsOfCareId: Ref<string | undefined>
  admissionDateDisplay: Ref<string>
  dischargeDateDisplay: Ref<string>
  eventTypeDisplay: Ref<string>
  dischargeDiagnosisDisplay: Ref<string>
  dischargeLocationDisplay: Ref<string>
  isReadmissionRiskDisplay: Ref<string>
  hasNewDmeOnDischargeDisplay: Ref<string>
  servicesOnDischargeDisplay: Ref<string>
  moreThanThreeAdmissionsInLastSixMonthsDisplay: Ref<string>
  summaryOfEventsDisplay: Ref<string>
  hasElectiveAdmissionDisplay: Ref<string>
  showEditModal: Ref<boolean>
  editModalInitialData: Ref<Partial<EditTransitionsOfCareForm>>
  toggleTransitionsOfCareEdit: () => void
  arrayToStringDisplay: (arr: string[]) => string
  formatStringDisplay: (str: string) => string
  refetchTransitionsOfCare: () => Promise<void>
}

/**
 *
 */
export function setup(): TransitionsOfCareViewModel {
  const showEditModal = ref<boolean>(false)
  const patientId = requiredInject(PATIENT_ID_KEY)
  const { data: transitionsOfCareData } = storeToRefs(
    useCarePlanTransitionsOfCareApi()
  )
  const transitionsOfCare = computed(
    () => transitionsOfCareData.value?.[0] ?? null
  )
  const transitionsOfCareId = computed(
    () => transitionsOfCare.value?.transitionsOfCareId
  )

  const admissionDateDisplay = computed(() =>
    transitionsOfCare.value?.admissionDate
      ? new Date(transitionsOfCare.value.admissionDate).toLocaleDateString()
      : '-'
  )

  const dischargeDateDisplay = computed(() =>
    transitionsOfCare.value?.dischargeDate
      ? new Date(transitionsOfCare.value.dischargeDate).toLocaleDateString()
      : '-'
  )

  const eventTypeDisplay = computed(() =>
    transitionsOfCare.value?.eventType
      ? startCase(camelCase(transitionsOfCare.value?.eventType))
      : '-'
  )

  const dischargeDiagnosisDisplay = computed(() =>
    transitionsOfCare.value?.dischargeDiagnosis
      ? startCase(camelCase(transitionsOfCare.value?.dischargeDiagnosis))
      : '-'
  )

  const dischargeLocationDisplay = computed(() =>
    transitionsOfCare.value?.dischargeLocation
      ? startCase(camelCase(transitionsOfCare.value?.dischargeLocation))
      : '-'
  )

  const isReadmissionRiskDisplay = computed(() =>
    transitionsOfCare.value?.isReadmissionRisk
      ? startCase(camelCase(transitionsOfCare.value?.isReadmissionRisk))
      : '-'
  )

  const hasNewDmeOnDischargeDisplay = computed(() => {
    const hasNewDme = transitionsOfCare.value?.hasNewDmeOnDischarge
    if (hasNewDme === undefined || hasNewDme === null) {
      return '-'
    }
    return hasNewDme ? 'Yes' : 'No'
  })

  const servicesOnDischargeDisplay = computed(() => {
    const services = transitionsOfCare.value?.servicesOnDischarge
    return services && services.length ? arrayToStringDisplay(services) : '-'
  })

  const moreThanThreeAdmissionsInLastSixMonthsDisplay = computed(() => {
    const moreThanThree =
      transitionsOfCare.value?.moreThanThreeAdmissionsInLastSixMonths
    if (moreThanThree === undefined || moreThanThree === null) {
      return '-'
    }
    return moreThanThree ? 'Yes' : 'No'
  })

  const summaryOfEventsDisplay = computed(
    () => transitionsOfCare.value?.summaryOfEvents ?? '-'
  )

  const hasElectiveAdmissionDisplay = computed(() => {
    const hasElective = transitionsOfCare.value?.hasElectiveAdmission
    if (hasElective === undefined || hasElective === null) {
      return '-'
    }
    return hasElective ? 'Yes' : 'No'
  })

  const editModalInitialData = computed(() => ({
    admissionDate: transitionsOfCare.value?.admissionDate
      ? new Date(transitionsOfCare.value.admissionDate)
      : undefined,
    dischargeDate: transitionsOfCare.value?.dischargeDate
      ? new Date(transitionsOfCare.value.dischargeDate)
      : null,
    eventType: transitionsOfCare.value?.eventType,
    dischargeDiagnosis: transitionsOfCare.value?.dischargeDiagnosis,
    dischargeLocation: transitionsOfCare.value?.dischargeLocation,
    isReadmissionRisk: transitionsOfCare.value?.isReadmissionRisk,
    hasNewDmeOnDischarge: transitionsOfCare.value?.hasNewDmeOnDischarge,
    servicesOnDischarge: transitionsOfCare.value?.servicesOnDischarge,
    moreThanThreeAdmissionsInLastSixMonths:
      transitionsOfCare.value?.moreThanThreeAdmissionsInLastSixMonths,
    summaryOfEvents: transitionsOfCare.value?.summaryOfEvents,
    hasElectiveAdmission: transitionsOfCare.value?.hasElectiveAdmission,
  }))

  const refetchTransitionsOfCare = async () => {
    await getCarePlanTransitionsOfCare(patientId.value)
  }

  /**
   *
   */
  function toggleTransitionsOfCareEdit() {
    showEditModal.value = !showEditModal.value
  }

  onBeforeMount(async () => {
    await getCarePlanTransitionsOfCare(patientId.value)
  })

  return {
    patientId,
    transitionsOfCareId,
    admissionDateDisplay,
    dischargeDateDisplay,
    eventTypeDisplay,
    dischargeDiagnosisDisplay,
    dischargeLocationDisplay,
    isReadmissionRiskDisplay,
    hasNewDmeOnDischargeDisplay,
    servicesOnDischargeDisplay,
    moreThanThreeAdmissionsInLastSixMonthsDisplay,
    summaryOfEventsDisplay,
    hasElectiveAdmissionDisplay,
    arrayToStringDisplay,
    formatStringDisplay,
    toggleTransitionsOfCareEdit,
    showEditModal,
    editModalInitialData,
    refetchTransitionsOfCare,
  }
}
