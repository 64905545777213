import { PropType } from 'vue'
import { object, string, InferType, array, boolean } from 'yup'

export const schema = object({
  fallInLastTwelveMonths: boolean().nullable(),
  fearOfFalling: boolean().nullable(),
  hasPeripheralNeuropathy: boolean().nullable(),
  sedatingMedications: array().of(string().required()).nullable(),
  otherSedatingMedications: string().nullable(),
  unsteadyWalkingOrStanding: boolean().nullable(),
  usesDmeAssistForAmbulation: boolean().nullable(),
  anticoagulation: array().of(string().required()).nullable(),
  otherAnticoagulation: string().nullable(),
  additionalContext: string().nullable(),
})

export type EditFallRiskForm = InferType<typeof schema>

export const editFallRiskProps = {
  patientId: {
    type: String,
    required: true,
  },
  fallRiskId: {
    type: String,
    default: null,
  },
  initialData: {
    type: Object as PropType<Partial<EditFallRiskForm>>,
    required: true,
  },
} as const
