<template>
  <div>
    <div class="overflow-y-auto flex flex-col gap-4">
      <div
        v-for="medicalConditionDisplay in medicalConditionsDisplay"
        :key="medicalConditionDisplay?.medicalConditionId"
        class="medical-conditions-wrapper"
        :class="{
          'is-editing': medicalConditionDisplay.isEditing,
        }"
      >
        <div class="flex justify-between items-center w-full">
          <div class="flex flex-col overflow-hidden">
            <div class="space-y-2 flex flex-col">
              <div class="flex flex-row items-center">
                <h5>
                  {{ medicalConditionDisplay.description }}
                </h5>
                <TIcon
                  v-if="medicalConditionDisplay.isSensitive"
                  icon="sensitive"
                  size="sm"
                  class="ml-3"
                  color="nash-tigerlily600"
                />
              </div>
              <div v-if="medicalConditionDisplay.attributesString">
                {{ medicalConditionDisplay.attributesString }}
              </div>
              <div v-if="medicalConditionDisplay.isEditing" class="w-full">
                <TMDropdownField
                  name="conditionSpecificAttributes"
                  class="w-1/2 my-2 bg-nash-neutral000"
                  option-label="label"
                  option-value="value"
                  :multiple="true"
                  :options="attributeOptions"
                  :model-value="
                    editAttrsInitialData.conditionSpecificAttributes ??
                    undefined
                  "
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <TMSecondaryButton
              v-if="medicalConditionDisplay.isEditing"
              label="Save"
              name="edit-medical-conditions-attrs"
              @click="onSubmit"
            />
            <TOIconDropdown
              v-else
              icon="meatball"
              :options="medicalConditionActions(medicalConditionDisplay)"
              @change="
                triggerMedicalConditionAction(
                  $event.value,
                  medicalConditionDisplay
                )
              "
            />
          </div>
        </div>
      </div>
      <div v-if="!medicalConditionsDisplay.length">
        No medical conditions documented.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TOIconDropdown from '@/legacy/nashville/dropdown/TOIconDropdown.vue'
import { setup } from './controller'
import { editMedicalConditionsProps } from './types'

export default defineComponent({
  components: {
    TIcon,
    TMDropdownField,
    TOIconDropdown,
    TMSecondaryButton,
  },
  props: editMedicalConditionsProps,
  emits: [
    'refetch',
    'setUpdateAttrsMedicalCondition',
    'setArchiveMedicalCondition',
  ],
  setup,
})
</script>
<style lang="scss">
.medical-conditions-wrapper {
  @apply flex justify-between items-center;
  @apply py-6 px-4;
  @apply border border-nash-neutral400 rounded-lg;
}

.is-editing {
  @apply bg-nash-purple100 border-nash-purple400;
}

.svg-white-override img,
.svg-white-override svg {
  filter: brightness(100);
}
</style>
