<template>
  <div class="overflow-visible card-style w-full">
    <div class="flex justify-between">
      <div class="w-full">
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row space-x-4 items-center">
            <h3 class="font-medium py-2 print-h3">Offboarding Summary</h3>
          </div>
          <div class="flex flex-row">
            <div v-tooltip.left="'Edit social summary'">
              <TMSecondaryButton
                icon="pencil"
                class="w-9 h-10 p-9 ml-3 p-4"
                @click="toggleOffboardingSummaryEdit"
              />
            </div>
          </div>
        </div>
        <div class="mt-3 flex flex-row">
          <div class="w-full">
            <div class="grid-container">
              <label class="t-grid-item">Reason for Offboarding</label>
              <div class="t-grid-item">
                {{ reasonOffboardingDisplay }}
              </div>
              <label class="t-grid-item">Reengagement Attempts</label>
              <div class="t-grid-item">
                {{ reengagementAttemptsDescriptionDisplay }}
              </div>
              <label class="t-grid-item">Additional Details</label>
              <div class="t-grid-item">
                {{ additionalDetailsDisplay }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import { PropType, defineComponent } from 'vue'
import { SocialSummary } from '@/legacy/types/patients/socialSummaries'
import setup from './controller'

export default defineComponent({
  components: {
    TMSecondaryButton,
  },
  props: {
    patientSocialSummary: {
      type: Object as PropType<SocialSummary | null>,
      required: true,
    },
  },
  emits: ['toggleOffboardingSummaryEdit'],
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
