import { FilterMetaType } from '@thyme/nashville/src/types/tables'
import { startCase } from 'lodash'
import { storeToRefs } from 'pinia'
import { ref, Ref, computed, ComputedRef } from 'vue'
import {
  getAvailableStatusOptions,
  isSubtaskOverdue,
  getSubtaskIconName,
  getPriorityIcon,
} from '@/legacy/components/patient/pathways/lib/subtask'
import {
  setupContractingEntityIdsFilter,
  setupSpecificRoleFilter,
} from '@/legacy/components/sharedTable/sharedTableFilters'
import { buildCommunicationUrl } from '@/legacy/libs/format'
import { useContractingEntities } from '@/legacy/store/catalog/contractingEntities'
import { useFlagStore } from '@/legacy/store/modules/flags/flags'
import { useProfileStore } from '@/legacy/store/modules/profile'
import { renderReattemptTooltip } from '@/legacy/store/modules/reattempts'
import { useStacksApi } from '@/legacy/store/modules/reminders'
import {
  Subtask,
  SubtaskStatus,
  openAssignedSubtaskStatuses,
  SubtaskRowDisplayType,
  SubtaskPriority,
} from '@/legacy/types/pathways/subtasks'
import { Stack } from '@/legacy/types/reminders'
import {
  podLeadViewBlankFillerColumn,
  patientColumn,
  carePodColumn,
  languageColumn,
  getTimingColumn,
  getSubtasksColumn,
  queueListParams,
  getFollowUpStacksData,
  sharedFilters,
  tierColumn,
} from '../lib/sharedQueueParts'

/**
 *
 * @param props
 */
export default function (props: any) {
  useContractingEntities()
  const { enablePatientContracts } = storeToRefs(useFlagStore())

  const table = ref<{
    getData: () => object
    filterMeta: Ref<FilterMetaType>
  } | null>(null)
  const getData = () => table.value?.getData()
  const { selfEntity: activeUser } = storeToRefs(useProfileStore())

  const showReattemptModal = ref(false)
  const selectedReattemptSubtask = ref<Subtask | null>(null)
  const showSubStatusModal = ref(false)
  const { showSurfacedStatusReason } = storeToRefs(useFlagStore())
  const selectedSubStatusSubtask = ref<Subtask | null>(null)
  const subtaskStatus = ref<SubtaskStatus | null>(null)

  const myQueueApiStore = computed(() =>
    props.responsibleStaffId
      ? props.customApiStore(props.responsibleStaffId)
      : useStacksApi
  )

  const shouldIncludeScheduledCalls = computed(() => !props.responsibleStaffId)

  /**
   * Function to close reattempt modal
   */
  function closeReattemptModal() {
    showReattemptModal.value = false
  }
  /**
   * Function to open reattempt modal
   * @param subtask
   */
  function openReattemptModal(subtask: Subtask) {
    showReattemptModal.value = true
    selectedReattemptSubtask.value = subtask
  }

  /**
   * Function to show/open SubStatusModal modal
   * @param subtask
   * @param status
   */
  function openSubStatusModal(subtask: Subtask, status: SubtaskStatus) {
    showSubStatusModal.value = true
    subtaskStatus.value = status
    selectedSubStatusSubtask.value = subtask
  }

  const columns = computed(() => [
    ...(props.responsibleStaffId ? [podLeadViewBlankFillerColumn] : []),
    patientColumn,
    ...(props.responsibleStaffId && !props.isEnrollmentSpecialist
      ? [carePodColumn]
      : []),
    tierColumn,
    languageColumn,
    getTimingColumn(false),
    getSubtasksColumn(false, !!props.responsibleStaffId),
  ])

  const subtaskStatusOptions = (subtaskStatus: SubtaskStatus) =>
    getAvailableStatusOptions(subtaskStatus)

  const params = computed(() => ({
    ...queueListParams,
    ...{
      filter_staff_ids: [
        props.responsibleStaffId ?? activeUser.value?.entityId,
      ],
      filter_subtask_status: [
        SubtaskStatus.OPEN_ASSIGNED,
        SubtaskStatus.IN_PROGRESS,
      ],
      show_scheduled_calls_first: false,
      ...(filters.value.roles?.value
        ? { roles: filters.value.roles.value }
        : {}),
    },
  }))

  const isPodLeadQueue = computed(() => !!props.responsibleStaffId)

  const getFollowUpDataForAssigned = async (stacks: Stack[]) => {
    await getFollowUpStacksData(
      stacks,
      false,
      isPodLeadQueue.value,
      props.isEnrollmentSpecialist,
      shouldIncludeScheduledCalls.value,
      {
        filter_subtask_status: openAssignedSubtaskStatuses,
        filter_staff_ids: [
          props.responsibleStaffId ?? activeUser.value?.entityId,
        ],
        ...(filters.value.roles?.value
          ? { roles: filters.value.roles.value }
          : {}),
      }
    )
  }

  const filters: ComputedRef<FilterMetaType> = computed(() => {
    return {
      ...sharedFilters(),
      ...(enablePatientContracts.value
        ? { contractingEntityIds: setupContractingEntityIdsFilter() }
        : {}),
      roles: setupSpecificRoleFilter(),
    }
  })

  const tableTitle = props.responsibleStaffId
    ? ''
    : 'Members for Today | Assigned to Me'

  return {
    filters,
    isSubtaskOverdue,
    openReattemptModal,
    SubtaskRowDisplayType,
    getFollowUpDataForAssigned,
    renderReattemptTooltip,
    selectedReattemptSubtask,
    showReattemptModal,
    closeReattemptModal,
    showSurfacedStatusReason,
    subtaskStatus,
    showSubStatusModal,
    selectedSubStatusSubtask,
    openSubStatusModal,
    table,
    columns,
    params,
    useStacksApi,
    subtaskStatusOptions,
    getSubtaskIconName,
    SubtaskStatus,
    getData,
    startCase,
    getPriorityIcon,
    SubtaskPriority,
    myQueueApiStore,
    buildCommunicationUrl,
    shouldIncludeScheduledCalls,
    tableTitle,
  }
}
