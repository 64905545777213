import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { usePhysicalFunctionAssessmentApi } from '../shared/store'
import { EditPhysicalFunctionAssessmentForm } from './types'

/**
 *
 * @param physicalFunctionAssessmentId
 * @param dirtyData
 */
export async function updatePhysicalFunctionAssessment(
  physicalFunctionAssessmentId: string,
  dirtyData: EditPhysicalFunctionAssessmentForm
) {
  let upserted
  try {
    upserted = await usePhysicalFunctionAssessmentApi().partialUpdate({
      ids: [physicalFunctionAssessmentId],
      body: dirtyData,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update physical function assessment.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated physical function assessment.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}

/**
 *
 * @param patientId
 * @param dirtyData
 */
export async function createPhysicalFunctionAssessment(
  patientId: string,
  dirtyData: EditPhysicalFunctionAssessmentForm
) {
  let upserted
  try {
    upserted = await usePhysicalFunctionAssessmentApi().create({
      body: { patientId: patientId, ...dirtyData },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update physical function assessment.',
      type: NotificationType.DANGER,
    })
    return null
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated physical function assessment.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}
