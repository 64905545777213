import { PropType } from 'vue'
import { array, boolean, date, InferType, object, string } from 'yup'

export const schema = object({
  admissionDate: date().required(),
  dischargeDate: date().nullable(),
  eventType: string().required(),
  dischargeDiagnosis: string().nullable(),
  dischargeLocation: string().nullable(),
  isReadmissionRisk: string().nullable(),
  hasNewDmeOnDischarge: boolean().nullable(),
  servicesOnDischarge: array().of(string()).nullable(),
  moreThanThreeAdmissionsInLastSixMonths: boolean().nullable(),
  summaryOfEvents: string().nullable(),
  hasElectiveAdmission: boolean().nullable(),
})
export type EditTransitionsOfCareForm = InferType<typeof schema>

export const editTransitionsOfCareProps = {
  patientId: {
    type: String,
    required: true,
  },
  initialData: {
    type: Object as PropType<Partial<EditTransitionsOfCareForm>>,
    required: true,
  },
  transitionsOfCareId: {
    type: String,
    required: false,
  },
} as const
