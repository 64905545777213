import { Nullable, UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'

export type FallRisk = {
  fallRiskId: UUID
  patientId: UUID
  fallInLastTwelveMonths: Nullable<boolean>
  unsteadyWalkingOrStanding: Nullable<boolean>
  fearOfFalling: Nullable<boolean>
  usesDmeAssistForAmbulation: Nullable<boolean>
  hasPeripheralNeuropathy: Nullable<boolean>
  anticoagulation: Anticoagulation[]
  otherAnticoagulation: Nullable<string>
  sedatingMedications: SedatingMedications[]
  otherSedatingMedications: Nullable<string>
  additionalContext: Nullable<string>
} & Timestamps

// Enums ----------------
export enum Anticoagulation {
  WARFARIN = 'WARFARIN',
  APIXABAN = 'APIXABAN',
  DABIGATRAN = 'DABIGATRAN',
  RIVAROXABAN = 'RIVAROXABAN',
  EDOXABAN = 'EDOXABAN',
  CLOPIDOGREL = 'CLOPIDOGREL',
  PRASUGREL = 'PRASUGREL',
  TICAGRELOR = 'TICAGRELOR',
  ASPIRIN = 'ASPIRIN',
  NSAIDS = 'NSAIDS',
  OTHER = 'OTHER',
}

export enum SedatingMedications {
  BENZODIAZEPINES = 'BENZODIAZEPINES',
  OPIOIDS = 'OPIOIDS',
  ANTICHOLINERGICS = 'ANTICHOLINERGICS',
  ANTIPSYCHOTICS = 'ANTIPSYCHOTICS',
  OTHER = 'OTHER',
}
