import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { camelCase, startCase } from 'lodash'
import { useForm } from 'vee-validate'
import { ExtractPropTypes } from 'vue'
import { booleanOptions } from '@/pages/PatientProfile/CarePlans/ClinicalSummary/shared/types'
import {
  DischargeLocation,
  EventType,
  ReadmissionRisk,
  ServicesOnDischarge,
} from '@/pages/PatientProfile/CarePlans/ClinicalSummary/TransitionsOfCare/shared/types'
import {
  createCarePlanTransitionsOfCare,
  updateCarePlanTransitionsOfCare,
} from './queries'
import {
  editTransitionsOfCareProps,
  EditTransitionsOfCareForm,
  schema,
} from './types'

type PropsType = ExtractPropTypes<typeof editTransitionsOfCareProps>

/**
 *
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  /**
   *
   */
  function close() {
    context.emit('close')
  }

  const { handleSubmit } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(schema),
  })

  const dischargeLocationOptions = Object.values(DischargeLocation)
    .map((val) => ({ value: val, label: startCase(camelCase(val)) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const readmissionRiskOptions = Object.values(ReadmissionRisk)
    .map((val) => ({ value: val, label: startCase(camelCase(val)) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const eventTypeOptions = Object.values(EventType)
    .map((val) => ({ value: val, label: startCase(camelCase(val)) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const servicesOnDischargeOptions = Object.values(ServicesOnDischarge)
    .map((val) => ({ value: val, label: startCase(camelCase(val)) }))
    .sort((a, b) => a.label.localeCompare(b.label))

  /**
   *
   * @param values
   */
  async function saveTransitionsOfCare(values: EditTransitionsOfCareForm) {
    if (props.transitionsOfCareId) {
      await updateCarePlanTransitionsOfCare(props.transitionsOfCareId, values)
    } else {
      await createCarePlanTransitionsOfCare(props.patientId, values)
    }

    context.emit('refetch')
    close()
  }

  const onSubmit = handleSubmit(saveTransitionsOfCare)

  return {
    close,
    ModalSize,
    dischargeLocationOptions,
    readmissionRiskOptions,
    eventTypeOptions,
    servicesOnDischargeOptions,
    booleanOptions,
    onSubmit,
  }
}
