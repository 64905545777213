import apiStore from '@/legacy/store/modules/apiBuilder'
import { CarePlanTransitionsOfCare } from './types'

export const useCarePlanTransitionsOfCareApi =
  apiStore<CarePlanTransitionsOfCare>(
    'carePlanTransitionsOfCareApi',
    '/api/care_plan/care_plan_transitions_of_care',
    {
      transformData: (d: { data: CarePlanTransitionsOfCare[] }) => d,
    }
  )
