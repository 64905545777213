import { Nullable, UUID } from '@/legacy/types/global/base'

export type CancerDiagnosis = {
  diagnosisDate: Nullable<string>
  additionalContext: Nullable<string>
  otherOncologyDxType: Nullable<string>
  oncologyDiagnosisRefId: Nullable<UUID>
  cancerDiagnosisId: UUID
  patientId: UUID
  oncologyDiagnosisReference: OncologyDiagnosisReference
  oncologySpreadRefMaps: MapCancerDiagnosisOncSpreadRef[]
  isPrimaryDiagnosis: Nullable<boolean>
}

// Reference tables
export type OncologyDiagnosisReference = {
  oncologyDiagnosisCategoryRefId: UUID
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  oncologyDiagnosisRefId: UUID
  oncologyDiagnosisCategoryReference: OncologyDiagnosisCategoryReference
}

export type OncologyDiagnosisCategoryReference = {
  oncologyDiagnosisCategoryRefId: UUID
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
}

export type MapCancerDiagnosisOncSpreadRef = {
  cancerDiagnosisId: UUID
  oncologySpreadRefId: UUID
  mapCancerDiagnosesOncSpreadRefId: UUID
  oncologySpreadReference: OncologySpreadReference
}

export type OncologySpreadReference = {
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  oncologySpreadRefId: UUID
}

export type PatientClinicalSummary = {
  cancerDiagnosis: CancerDiagnosis
  lastTreatmentDate: string | null
  ecogStatus: ECOGStatus | null
  tocLastDischargeDate: string | null
  medicalConditions: MedicalCondition[]
}

export type MedicalCondition = {
  isArchived: boolean
  conditionSpecificAttributes: UUID[]
  medicalConditionId: UUID
  patientId: UUID
  medicalConditionRefId: UUID
  medicalConditionReference: MedicalConditionReference
  otherMedicalCondition: string | null
}

export type MedicalConditionReference = {
  description: string
  isActive: boolean
  isSensitive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  medicalConditionRefId: UUID | null
}

export enum ECOGStatus {
  FULLY_ACTIVE = 'FULLY_ACTIVE',
  LIGHT_WORK_ONLY = 'LIGHT_WORK_ONLY',
  UNABLE_TO_WORK_UP_OVER_50_PERCENT = 'UNABLE_TO_WORK_UP_OVER_50_PERCENT',
  IN_BED_OR_CHAIR_OVER_50_PERCENT = 'IN_BED_OR_CHAIR_OVER_50_PERCENT',
  FULLY_DEPENDENT = 'FULLY_DEPENDENT',
}
