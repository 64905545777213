import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useMedicalTeamApi } from '../shared/store'
import { EditMedicalTeamForm } from './types'

/**
 *
 * @param patientMedTeamId
 * @param dirtyData
 */
export async function updateMedicalTeam(
  patientMedTeamId: string,
  dirtyData: EditMedicalTeamForm
) {
  let upserted
  try {
    upserted = await useMedicalTeamApi().partialUpdate({
      ids: [patientMedTeamId],
      body: dirtyData,
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update medical team.',
      type: NotificationType.DANGER,
    })
    return
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated medical team.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}

/**
 *
 * @param patientId
 * @param dirtyData
 */
export async function createMedicalTeam(
  patientId: string,
  dirtyData: EditMedicalTeamForm
) {
  let upserted
  try {
    upserted = await useMedicalTeamApi().create({
      body: { patientId: patientId, ...dirtyData },
    })
  } catch (err) {
    useNotificationStore().setNotification({
      message: 'Failed to update medical team.',
      type: NotificationType.DANGER,
    })
    return null
  }
  useNotificationStore().setNotification({
    message: 'Successfully updated medical team.',
    type: NotificationType.SUCCESS,
  })
  return upserted
}
