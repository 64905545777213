import apiStore from '@/legacy/store/modules/apiBuilder'
import { MedicalCondition, PatientClinicalSummary } from './types'

export const usePatientClinicalSummaryApi = apiStore<PatientClinicalSummary>(
  'patientClinicalSummaryApi',
  '/api/care_plan/patient_clinical_summary'
)

export const useMedicalConditionsCarePlanApi = apiStore<MedicalCondition>(
  'medicalConditionsCarePlanApi',
  '/api/medical_conditions',
  {
    transformData: (d: { data: MedicalCondition[] }) => d,
  }
)
