import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useMedicalTeamApi } from './shared/store'
import { MedicalTeam } from './shared/types'

/**
 * Retrieve the patient medical team for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPatientMedicalTeam(
  patientId: string
): Promise<MedicalTeam | null> {
  const medicalTeamApi = useMedicalTeamApi()
  const medicalTeamRes = await medicalTeamApi.list({
    params: {
      filter_patient_ids: [patientId],
    },
  })
  if (!medicalTeamRes?.data?.length) {
    return null
  }

  if (medicalTeamApi.error) {
    const error = medicalTeamApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient medical team`,
      type: NotificationType.WARNING,
      error: `Error fetching patient medical team: ${error}`,
    })
  }
  return medicalTeamRes.data[0]
}
