import { Nullable, UUID } from '@/legacy/types/global/base'

export type MapMedicalAttrsMedicalConditions = {
  medicalConditionAttributeId: UUID
  medicalConditionRefId: UUID
  mapMedConditionAttrRefMedConditionRefId: UUID
}

export type MedicalConditionAttributeReference = {
  description: string
  isActive: boolean
  activeFrom: string
  activeTo: Nullable<string>
  medicalConditionAttributeId: UUID
}

export type MedicalConditionDisplay = {
  isArchived: boolean
  attributesString: null | string
  medicalConditionId: UUID
  isSensitive: boolean
  medicalConditionRefId: UUID | null
  description: string
  conditionSpecificAttributes: string[] | null
  hasConditionSpecificAttributes?: boolean
  patientId: UUID
}

/**
 * We are currently setting page length to 50
 * for all medical conditions reference calls.
 *
 * Currently, we only have roughly 20-25 medical condition references
 * as well as medical condition attribute references.
 *
 * Will need to officially implement pagination and set page length to a lower
 * number in the future when and if the team decides to significantly increase
 * the number of references.
 */
export const MEDICAL_CONDITIONS_PAGE_LENGTH = 50
