<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5"
  >
    <div class="flex justify-between">
      <div class="w-full relative">
        <div>
          <TSpinner
            v-if="isLoadingMedicalConditions"
            name="loading-medical-conditions"
            :partial-page="true"
          />
          <div
            v-for="medConditionWithAttrs in medicalConditionsWithAttrsDisplay"
            :key="medConditionWithAttrs.medicalConditionId"
            class="grid-container"
          >
            <div class="grid-item">
              <label>{{ medConditionWithAttrs.description }}</label>
              <TIcon
                v-if="medConditionWithAttrs.isSensitive"
                icon="sensitive"
                size="sm"
                class="ml-2 inline"
                color="nash-tigerlily600"
              />
            </div>
            <div class="grid-item">
              {{ medConditionWithAttrs.attributesString }}
            </div>
          </div>
        </div>
        <TMTertiaryButton
          :label="otherMedicalConditionsLabelDisplay"
          class="pt-2 mb-3"
          icon-pos="right"
          :icon="showOtherMedicalConditions ? 'chevronUp' : 'chevronDown'"
          @click="fetchAndShowOtherMedicalConditions"
        />
        <div v-if="showOtherMedicalConditions" class="w-full relative">
          <div v-if="medicalConditionsWithoutAttrsDisplay.length">
            <div
              v-for="medConditionWithoutAttrs in medicalConditionsWithoutAttrsDisplay"
              :key="medConditionWithoutAttrs.medicalConditionId"
              class="non-attr-label py-2"
            >
              <label>{{ medConditionWithoutAttrs.description }}</label>
              <TIcon
                v-if="medConditionWithoutAttrs.isSensitive"
                icon="sensitive"
                size="sm"
                class="ml-2 inline"
                color="nash-tigerlily600"
              />
            </div>
          </div>
          <div
            v-if="
              !medicalConditionsWithoutAttrsDisplay.length &&
              !isLoadingMedicalConditionsWithoutAttrs
            "
          >
            This patient has no other medical conditions documented.
          </div>
        </div>
      </div>
      <div v-tooltip.left="'Manage Medical Conditions'">
        <TMSecondaryButton
          icon="pencil"
          class="w-9 h-10 p-9 ml-3 p-4"
          @click="toggleManageMedicalConditions"
        />
      </div>
    </div>
  </div>
  <ManageMedicalConditions
    v-if="showManageModal"
    :patient-id="patientId"
    :medical-conditions-without-attrs-display="
      medicalConditionsWithoutAttrsDisplay
    "
    :medical-conditions-with-attrs-display="medicalConditionsWithAttrsDisplay"
    @close="toggleManageMedicalConditions"
    @refetch="fetchAll"
    @fetch-medical-conditions-without-attrs="fetchMedicalConditionsWithoutAttrs"
  />
</template>

<script lang="ts">
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import TIcon from '@nashville/icon/TIcon.vue'
import { defineComponent } from 'vue'
import TSpinner from '@/legacy/nashville/spinner/TSpinner.vue'
import { setup } from './controller'
import ManageMedicalConditions from './ManageMedicalConditions/ManageMedicalConditions.vue'

export default defineComponent({
  components: {
    TIcon,
    TSpinner,
    ManageMedicalConditions,
    TMSecondaryButton,
    TMTertiaryButton,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0;
}
/* Apply background neutral100 to every other 2 grid-items */
.grid-container:nth-child(odd),
.non-attr-label:nth-child(odd) {
  @apply bg-nash-neutral100;
}
</style>
