import { Nullable } from 'vitest'
import { UUID } from '@/legacy/types/global/base'
import { Timestamps } from '@/legacy/types/global/dates'
import { ECOGStatus } from '@/pages/PatientProfile/CarePlans/shared/types'

export type PhysicalFunctionAssessment = {
  physicalFunctionAssessmentId: UUID
  patientId: UUID
  ecogStatus: ECOGStatus
  iadlsInability: IADLsInability[]
  adlsInability: ADLsInability[]
  unableToLeaveHomeOtherThanMedicalAppts: Nullable<boolean>
  dmeAtHome: DME[]
  proceduralSupport: ProceduralSupport[]
  unableToWalkTwoBlocksOrAFlightOfStairs: Nullable<boolean>
  additionalContext: Nullable<string>
} & Timestamps

// Enums ----------------
export enum IADLsInability {
  TELEPHONE = 'TELEPHONE',
  SHOPPING = 'SHOPPING',
  FOOD_PREPARATION = 'FOOD_PREPARATION',
  HOUSEKEEPING = 'HOUSEKEEPING',
  LAUNDRY = 'LAUNDRY',
  FINANCES = 'FINANCES',
  TRANSPORTATION = 'TRANSPORTATION',
  MEDICATION_PREPARATION = 'MEDICATION_PREPARATION',
}

export enum ADLsInability {
  TOILETING = 'TOILETING',
  BATHING = 'BATHING',
  EATING_FEEDING = 'EATING_FEEDING',
  DRESSING = 'DRESSING',
  GROOMING = 'GROOMING',
  TRANSFERRING = 'TRANSFERRING',
  WALKING = 'WALKING',
}

export enum DME {
  CANE = 'CANE',
  WALKER = 'WALKER',
  ROLLATOR = 'ROLLATOR',
  WHEELCHAIR = 'WHEELCHAIR',
  SCOOTER = 'SCOOTER',
  LIFT = 'LIFT',
  COMMODE = 'COMMODE',
  OXYGEN = 'OXYGEN',
  SHOWER_RAILS = 'SHOWER_RAILS',
  SHOWER_BENCH = 'SHOWER_BENCH',
  CPAP = 'CPAP',
  HOSPITAL_BED = 'HOSPITAL_BED',
}

export enum ProceduralSupport {
  TRACHEOSTOMY = 'TRACHEOSTOMY',
  FEEDING_TUBE = 'FEEDING_TUBE',
  ILEOSTOMY = 'ILEOSTOMY',
  COLOSTOMY = 'COLOSTOMY',
  CYSTOSTOMY = 'CYSTOSTOMY',
  UROSTOMY = 'UROSTOMY',
  NEPHROSTOMY = 'NEPHROSTOMY',
  INDWELLING_URINARY_CATHETER = 'INDWELLING_URINARY_CATHETER',
  SURGICAL_TUBE_DRAIN = 'SURGICAL_TUBE_DRAIN',
}
