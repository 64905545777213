import { ModalSize } from '@thyme/nashville/src/types/modals'
import { toTypedSchema } from '@vee-validate/yup'
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { computed, ExtractPropTypes, onBeforeMount, ref } from 'vue'
import {
  createPatientCancerDiagnosis,
  getCancerSpread,
  getOncologyDiagnosisCategoryReference,
  getOncologyDiagnosisReferenceByCategoryRefId,
  updatePatientCancerDiagnosis,
} from './queries'
import {
  useOncologyDiagnosisCategoryReferenceApi,
  useOncologyDiagnosisReferenceApi,
  useOncologySpreadReferenceApi,
} from './store'
import { schema, EditCancerDetailsForm, editCancerDetailsProps } from './types'

type PropsType = ExtractPropTypes<typeof editCancerDetailsProps>

const otherString = 'other'
/**
 * Set up the EditPatientCancerDetails component
 * @param props
 * @param context
 */
export function setup(props: PropsType, context: any) {
  const { data: cancerSpread } = storeToRefs(useOncologySpreadReferenceApi())
  const { data: oncologyDiagnosisReference } = storeToRefs(
    useOncologyDiagnosisReferenceApi()
  )
  const { data: oncologyDiagnosisCategoryReference } = storeToRefs(
    useOncologyDiagnosisCategoryReferenceApi()
  )

  const spreadOptions = computed(() =>
    cancerSpread.value
      ?.map((spread) => ({
        value: spread.oncologySpreadRefId,
        label: spread.description,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label))
  )

  const cancerOptions = computed(() => {
    const oncRef = oncologyDiagnosisCategoryReference.value ?? null
    if (oncRef) {
      const otheroption = [{ label: 'Other', value: otherString }]
      const cancerArr = oncRef
        .map((dxRef) => ({
          value: dxRef.oncologyDiagnosisCategoryRefId,
          label: dxRef.description,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label))
      return [...cancerArr, ...otheroption]
    }
    return []
  })

  const cancerInitialValue = computed(() => {
    if (props.initialData.oncologyDiagnosisCategoryRefId === null) {
      return otherString
    }
    if (props.initialData.oncologyDiagnosisCategoryRefId) {
      return props.initialData.oncologyDiagnosisCategoryRefId
    }
    return undefined
  })

  const cancerTypeOptions = computed(() =>
    oncologyDiagnosisReference.value
      ?.map((dxRef) => ({
        value: dxRef.oncologyDiagnosisRefId,
        label: dxRef.description,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label))
  )

  const { handleSubmit, setFieldValue } = useForm({
    initialValues: props.initialData,
    validationSchema: toTypedSchema(schema),
  })

  const showOtherCancerType = ref(
    !props.initialData.oncologyDiagnosisCategoryRefId
  )

  /**
   * set oncologyDiagnosisCategoryRefId field
   * fetch cancer type values associated with cancer value
   * @param oncologyDiagnosisCategoryRefId
   */
  async function setCancerField(oncologyDiagnosisCategoryRefId: string) {
    setFieldValue('oncologySpreadRefIds', [])
    // if 'other' was selected in cancer dropdown
    // set other dropdowns to null and render text area for Cancer Type field
    if (oncologyDiagnosisCategoryRefId === otherString) {
      setFieldValue('oncologyDiagnosisCategoryRefId', null)
      showOtherCancerType.value = true
    } else {
      // if any other option was selected, render dropdown for Cancer Type field
      // set val for cancer field
      // fetch associated cancer type options
      showOtherCancerType.value = false
      setFieldValue(
        'oncologyDiagnosisCategoryRefId',
        oncologyDiagnosisCategoryRefId
      )
      await getOncologyDiagnosisReferenceByCategoryRefId(
        oncologyDiagnosisCategoryRefId
      )
    }
    return
  }

  /**
   *
   * @param oncologyDiagnosisRefId
   */
  function setCancerType(oncologyDiagnosisRefId: string) {
    setFieldValue('oncologyDiagnosisRefId', oncologyDiagnosisRefId)
    setFieldValue('oncologySpreadRefIds', [])
  }

  /**
   * Emit back close modal function to parent component
   */
  function close() {
    context.emit('close')
  }

  /**
   * oncologyDiagnosisRefId and/or otherOncologyDxType is sometimes absent in
   * payload and sometimes oncologyDiagnosisCategoryRefId incorrectly shows up
   * @param values
   */
  function cleanPayloadValues(values: EditCancerDetailsForm) {
    const copy = { ...values }
    delete copy.oncologyDiagnosisCategoryRefId

    if (copy.otherOncologyDxType) {
      copy.oncologyDiagnosisRefId = null
    } else if (copy.oncologyDiagnosisRefId) {
      copy.otherOncologyDxType = null
    }
    return copy
  }

  /**
   *
   * @param values
   * update existing cancer diagnosis
   * or create new one if there is none
   */
  async function savePatientCancerDetails(values: EditCancerDetailsForm) {
    const cleanedValues = cleanPayloadValues(values)
    if (props.cancerDetailsId) {
      await updatePatientCancerDiagnosis(props.cancerDetailsId, cleanedValues)
    } else {
      await createPatientCancerDiagnosis(props.patientId, cleanedValues)
    }
    context.emit('refetch')
    close()
  }

  const onSubmit = handleSubmit(savePatientCancerDetails)

  onBeforeMount(async () => {
    await getCancerSpread()
    await getOncologyDiagnosisCategoryReference()
    if (props.initialData.oncologyDiagnosisCategoryRefId) {
      await getOncologyDiagnosisReferenceByCategoryRefId(
        props.initialData.oncologyDiagnosisCategoryRefId
      )
    }
  })

  return {
    ModalSize,
    // dropdown options
    spreadOptions,
    cancerOptions,
    cancerTypeOptions,
    // conditional text area render
    showOtherCancerType,
    // set/initialize dropdown values
    setFieldValue,
    setCancerField,
    setCancerType,
    cancerInitialValue,
    // actions
    close,
    onSubmit,
  }
}
