<template>
  <div>
    <TModal
      :flex="true"
      :is-visible="true"
      title="Edit Transitions of Care"
      :allow-overflow="true"
      :size="ModalSize.MD"
      @close="close"
    >
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div>
            <TDatePicker
              name="admissionDate"
              label="Admission Date"
              class="w-full my-2"
              :is-datetime="false"
              :model-value="
                initialData.admissionDate
                  ? new Date(initialData.admissionDate)
                  : null
              "
            />
          </div>
          <div>
            <TMDropdownField
              name="eventType"
              label="Event Type"
              class="w-full my-2"
              :options="eventTypeOptions"
              option-label="label"
              option-value="value"
              :model-value="initialData.eventType"
            />
          </div>
          <div>
            <TMDropdownField
              name="hasElectiveAdmission"
              label="Elective Admission"
              class="w-full my-2"
              :options="booleanOptions"
              option-label="label"
              option-value="value"
              :model-value="initialData.hasElectiveAdmission ?? undefined"
            />
          </div>
          <div>
            <TMDropdownField
              name="dischargeLocation"
              label="Discharge Location"
              class="w-full my-2"
              :options="dischargeLocationOptions"
              option-label="label"
              option-value="value"
              :model-value="initialData.dischargeLocation ?? undefined"
            />
          </div>
          <div>
            <TMDropdownField
              name="servicesOnDischarge"
              label="Services on Discharge"
              class="w-full my-2"
              :options="servicesOnDischargeOptions"
              option-label="label"
              option-value="value"
              :multiple="true"
              :model-value="initialData.servicesOnDischarge ?? undefined"
            />
          </div>
        </div>
        <div>
          <div>
            <TDatePicker
              name="dischargeDate"
              label="Discharge Date"
              class="w-full my-2"
              :is-datetime="false"
              :model-value="
                initialData.dischargeDate
                  ? new Date(initialData.dischargeDate)
                  : null
              "
            />
          </div>
          <div>
            <TInputText
              name="dischargeDiagnosis"
              label="Discharge Diagnosis"
              class="w-full my-2"
              :model-value="initialData.dischargeDiagnosis ?? undefined"
            />
          </div>
          <div>
            <TMDropdownField
              name="hasNewDmeOnDischarge"
              label="New DME on Discharge"
              class="w-full my-2"
              :options="booleanOptions"
              option-label="label"
              option-value="value"
              :model-value="initialData.hasNewDmeOnDischarge ?? undefined"
            />
          </div>
          <div>
            <TMDropdownField
              name="isReadmissionRisk"
              label="Readmission Risk"
              class="w-full my-2"
              :options="readmissionRiskOptions"
              option-label="label"
              option-value="value"
              :model-value="initialData.isReadmissionRisk ?? undefined"
            />
          </div>
          <div>
            <TMDropdownField
              name="moreThanThreeAdmissionsInLastSixMonths"
              label=">3 Admissions in 6 Months"
              class="w-full my-2"
              :options="booleanOptions"
              option-label="label"
              option-value="value"
              :model-value="
                initialData.moreThanThreeAdmissionsInLastSixMonths ?? undefined
              "
            />
          </div>
        </div>
      </div>

      <label class="font-bold mb-2">Summary of Events</label>
      <TTextarea
        name="summaryOfEvents"
        :model-value="initialData.summaryOfEvents ?? ''"
      />

      <template #actions>
        <TMSecondaryButton
          label="Cancel"
          name="transitions-of-care-cancel"
          @click="close"
        />
        <TMPrimaryButton
          name="transitions-of-care-save"
          label="Save"
          @click="onSubmit"
        />
      </template>
    </TModal>
  </div>
</template>

<script lang="ts">
import TMPrimaryButton from '@nashville/button/TMPrimaryButton.vue'
import TMSecondaryButton from '@nashville/button/TMSecondaryButton.vue'
import TInputText from '@nashville/forms/TInputText/TInputText.vue'
import { defineComponent } from 'vue'
import TMDropdownField from '@/legacy/nashville/dropdown/TMDropdownField.vue'
import TTextarea from '@/legacy/nashville/input/TTextarea.vue'
import TDatePicker from '@/legacy/nashville/TDatePicker.vue'
import TModal from '@/legacy/nashville/TModal.vue'
import { setup } from './controller'
import { editTransitionsOfCareProps } from './types'

export default defineComponent({
  components: {
    TMPrimaryButton,
    TMDropdownField,
    TModal,
    TInputText,
    TTextarea,
    TMSecondaryButton,
    TDatePicker,
  },
  props: editTransitionsOfCareProps,
  emits: ['close', 'refetch'],
  setup,
})
</script>
