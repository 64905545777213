import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { useFallRiskApi } from './shared/store'
import { FallRisk } from './shared/types'

/**
 * Retrieve the patient fall risk for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 */
export async function getPatientFallRisk(
  patientId: string
): Promise<FallRisk | null> {
  const fallRiskApi = useFallRiskApi()
  const fallRiskResponse = await fallRiskApi.list({
    params: {
      filter_patient_ids: [patientId],
    },
  })
  if (!fallRiskResponse?.data?.length) {
    return null
  }

  if (fallRiskApi.error) {
    const error = fallRiskApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient fall risk`,
      type: NotificationType.WARNING,
      error: `Error fetching patient fall risk: ${error}`,
    })
  }
  return fallRiskResponse.data[0]
}
