<template>
  <div
    class="overflow-visible card-style pt-4 w-full mb-5 section-border-bottom pb-5"
  >
    <div class="flex justify-between">
      <div class="w-full">
        <div class="grid-container">
          <div class="grid-item">
            <label>Treatment</label>
          </div>
          <div class="grid-item">
            <div>
              <div v-for="(treatment, index) in treatmentDisplay" :key="index">
                {{ treatment }}
              </div>
            </div>
            <div>
              <TMTertiaryButton
                v-if="showMoreTreatmentsButton"
                label="Show More"
                class="pt-2 mb-3"
                icon-pos="right"
                :icon="'chevronDown'"
                @click="loadMore(TreatmentField.TREATMENT)"
              />
            </div>
          </div>
          <div class="grid-item">
            <label>Surgery</label>
          </div>
          <div class="grid-item">
            <div v-for="(surgery, index) in surgeryDisplay" :key="index">
              {{ surgery }}
            </div>
            <div>
              <TMTertiaryButton
                v-if="showMoreSurgeriesButton"
                label="Show More"
                class="pt-2 mb-3"
                icon-pos="right"
                :icon="'chevronDown'"
                @click="loadMore(TreatmentField.SURGERY)"
              />
            </div>
          </div>
          <div class="grid-item">
            <label>Radiation</label>
          </div>
          <div class="grid-item">
            <div v-for="(radiation, index) in radiationDisplay" :key="index">
              {{ radiation }}
            </div>
            <div>
              <TMTertiaryButton
                v-if="showMoreRadiationDatesButton"
                label="Show More"
                class="pt-2 mb-3"
                icon-pos="right"
                :icon="'chevronDown'"
                @click="loadMore(TreatmentField.RADIATION)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TMTertiaryButton from '@nashville/button/TMTertiaryButton.vue'
import { defineComponent } from 'vue'
import { setup } from './controller'

export default defineComponent({
  components: {
    TMTertiaryButton,
  },
  setup,
})
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.grid-item {
  height: 100%;
  padding: 7px 15px 7px 0;
}
</style>
