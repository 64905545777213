import { useNotificationStore } from '@/legacy/store/modules/notification'
import { NotificationType } from '@/legacy/types/notifications'
import { MedicalCondition } from '@/pages/PatientProfile/CarePlans/shared/types'
import {
  useMapMedicalAttrsMedicalConditionsApi,
  useMedicalConditionAttrsRefApi,
  useMedicalConditionsWithAttrsApi,
  useMedicalConditionsWithoutAttrsApi,
} from './shared/store'
import {
  MapMedicalAttrsMedicalConditions,
  MEDICAL_CONDITIONS_PAGE_LENGTH,
  MedicalConditionAttributeReference,
} from './shared/types'

/**
 * Retrieve the attribute refs for provided ref ids.
 * Returns null if no data is returned.
 * @param medicalConditionAttributeIds
 */
export async function getMedicalConditionAttrRefs(
  medicalConditionAttributeIds: string[] = []
): Promise<MedicalConditionAttributeReference[] | null> {
  const medicalConditionAttrsRefApi = useMedicalConditionAttrsRefApi()
  const attsRefRes = await medicalConditionAttrsRefApi.list({
    params: {
      filter_med_condition_attr_ref_ids: medicalConditionAttributeIds,
      filter_is_active: true,
      page_length: MEDICAL_CONDITIONS_PAGE_LENGTH,
    },
  })
  if (!attsRefRes?.data?.length) {
    return null
  }

  if (medicalConditionAttrsRefApi.error) {
    const error = medicalConditionAttrsRefApi.error
    useNotificationStore().setNotification({
      message: `Error fetching medical condition attributes refs`,
      type: NotificationType.WARNING,
      error: `Error fetching medical condition attributes refs: ${error}`,
    })
  }
  return attsRefRes.data
}

/**
 * Retrieve the attribute mappings for provided medical condition ref ids.
 * Returns null if no data is returned.
 * @param medicalConditionRefIds
 * @param medicalConditionAttributeIds
 */
export async function getMapMedicalAttrsMedicalConditions(
  medicalConditionRefIds: string[] = [],
  medicalConditionAttributeIds: string[] = []
): Promise<MapMedicalAttrsMedicalConditions[] | null> {
  const mapMedicalAttrsMedicalConditionsApi =
    useMapMedicalAttrsMedicalConditionsApi()
  const mappingsRes = await mapMedicalAttrsMedicalConditionsApi.list({
    params: {
      filter_med_condition_ref_ids: medicalConditionRefIds,
      filter_med_condition_attr_ref_ids: medicalConditionAttributeIds,
      page_length: MEDICAL_CONDITIONS_PAGE_LENGTH,
    },
  })
  if (!mappingsRes?.data?.length) {
    return null
  }

  if (mapMedicalAttrsMedicalConditionsApi.error) {
    const error = mapMedicalAttrsMedicalConditionsApi.error
    useNotificationStore().setNotification({
      message: `Error fetching medical conditions <> medical attributes mappings`,
      type: NotificationType.WARNING,
      error: `Error fetching medical conditions <> medical attributes mappings: ${error}`,
    })
  }
  return mappingsRes.data
}

/**
 * Retrieve the patient medical conditions for a single patient.
 * Returns null if no data is returned.
 * @param patientId
 * @param getConditionsWithAttrs
 */
export async function getPatientMedicalConditions(
  patientId: string,
  getConditionsWithAttrs: boolean | null = false
): Promise<MedicalCondition[] | null> {
  const medicalConditionsApi = getConditionsWithAttrs
    ? useMedicalConditionsWithAttrsApi()
    : useMedicalConditionsWithoutAttrsApi()
  const medicalConditionsRes = await medicalConditionsApi.list({
    params: {
      filter_patient_ids: [patientId],
      ...(getConditionsWithAttrs
        ? { filter_has_condition_specific_attributes: true }
        : getConditionsWithAttrs === false
        ? { filter_has_condition_specific_attributes: false }
        : {}),
      parts: ['medical_condition_reference'],
      filter_is_archived: false,
    },
  })
  if (!medicalConditionsRes?.data?.length) {
    return null
  }

  if (medicalConditionsApi.error) {
    const error = medicalConditionsApi.error
    useNotificationStore().setNotification({
      message: `Error fetching patient medical conditions`,
      type: NotificationType.WARNING,
      error: `Error fetching patient medical conditions: ${error}`,
    })
  }
  return medicalConditionsRes.data
}
